import IconComponent from 'components/Icon/Icon'
import React from 'react'
import LoaderComponent from 'components/Loader/Loader'
import ISimpleChartProps from './SimpleChart.interface'
import styles from './SimpleChart.module.scss'

const SimpleChart = (props: ISimpleChartProps) => {
  const {
    maxValue,
    name,
    onClick = () => {},
    unit,
    value,
    warningUpperSet,
    warningLowerSet,
    errorUpperSet,
    errorLowerSet,
    alarm = {
      min: 0,
      max: 0,
    },
    warning = {
      min: 0,
      max: 0,
    },
    loading = false,
  } = props
  let backgroundColor = '#31820e'
  if ((warningLowerSet && value < warning.min) || (value > warning.max && warningUpperSet)) {
    backgroundColor = '#dd620b'
  }
  if ((errorLowerSet && value < alarm.min) || (errorUpperSet && value > alarm.max)) {
    backgroundColor = '#E30000'
  }

  const displayValue = Math.max(value, 0)

  const renderStatisticContent = () => {
    if (loading) {
      return <LoaderComponent style={{ height: '29px' }} />
    }
    return (
      <div
        className={styles.simpleChart__container}
        onClick={onClick}
        aria-hidden="true"
      >
        <div className={styles.simpleChart__titleContainer}>
          <span className={styles.simpleChart__title}>{name}</span>
          <span className={styles.simpleChart__details}>
            {`${displayValue.toLocaleString('de', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} `}
            {unit}
          </span>
        </div>
        <div className={styles.simpleChart__barContainer}>
          <div
            className={styles.simpleChart__bar}
            style={{
              backgroundColor,
              width: `${value ? (value / maxValue) * 100 : 0}%`,
            }}
          />
          <div
            className={`${styles.simpleChart__bar} ${styles['simpleChart__bar--placeholder']}`}
            style={{ width: `calc(100% - ${value ? (value / maxValue) * 100 : 0}%)` }}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.simpleChart__containerExternal}>
      {renderStatisticContent()}
      {onClick && (
        <IconComponent
          className={styles.simpleChart__goForwardIcon}
          iconName="chevronRight"
          onClick={onClick}
        />
      )}
    </div>
  )
}

export default SimpleChart
