import IconComponent from 'components/Icon/Icon'
import useTimePeriodSelector from 'hooks/useTimePeriodSelector'
import React from 'react'
import ITimePeriodSelectorProps from './TimePeriodSelector.interface'
import styles from './TimePeriodSelector.module.scss'

const TimePeriodSelectorComponent: React.FC<ITimePeriodSelectorProps> = ({
  displayTimePeriodsSwitch,
  selectedTime,
  selectedTimePeriod,
  setSelectedTime,
  setSelectedTimePeriod,
}) => {
  const {
    handleTimePeriodArrowClick,
    handleTimePeriodClick,
    TimePeriodSelector,
    timePeriods,
    TIME_PERIOD_DIRECTION,
  } = useTimePeriodSelector({
    selectedTime,
    selectedTimePeriod,
    setSelectedTime,
    setSelectedTimePeriod,
    styles,
  })

  return (
    <div className={styles.timePeriodSelector__container}>
      <div className={styles.timePeriodSelector__containerInternal}>
        <div className={styles.timePeriodSelector__leftContainer}>
          <div className={styles.timePeriodSelector__calendarContainer}>
            <TimePeriodSelector />
          </div>
          <div className={styles.timePeriodSelector__arrowsContainer}>
            <IconComponent
              className={`${styles.timePeriodSelector__arrow} ${styles['timePeriodSelector__arrow--prev']}`}
              iconName="angleLeft"
              onClick={() =>
                handleTimePeriodArrowClick(TIME_PERIOD_DIRECTION.PREV)}
            />
            <IconComponent
              className={`${styles.timePeriodSelector__arrow} ${styles['timePeriodSelector__arrow--next']}`}
              iconName="angleRight"
              onClick={() =>
                handleTimePeriodArrowClick(TIME_PERIOD_DIRECTION.NEXT)}
            />
          </div>
        </div>
        {displayTimePeriodsSwitch && (
          <div className={styles.timePeriodSelector__rightContainer}>
            <div className={styles.timePeriodSelector__periodSelector}>
              {timePeriods.map((timePeriod) => (
                <span
                  className={`${styles.timePeriodSelector__period} ${
                    selectedTimePeriod === timePeriod.name
                      ? styles['timePeriodSelector__period--active']
                      : ''
                  }`}
                  key={timePeriod.name}
                  onClick={() => handleTimePeriodClick(timePeriod)}
                  onKeyDown={() => handleTimePeriodClick(timePeriod)}
                  role="presentation"
                >
                  {timePeriod.name}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TimePeriodSelectorComponent
