import IconComponent from 'components/Icon/Icon'
import { IMessageProps } from 'hooks/useMessageList'
import usePlsTranslation from 'hooks/usePlsTranslation'
import React from 'react'
import styles from './Message.module.scss'

const Message = ({
  content, date, id, status, time, title,
}: IMessageProps) => {
  const { translate } = usePlsTranslation()

  return (
    <div className={styles.message__container}>
      <div className={`${styles.message__line} ${styles['message__line--2']}`}>
        <p className={styles.message__title}>{title}</p>
        <div
          className={`${styles.message__group} ${styles['message__group--1']}`}
        >
          {false && status && id > 0 && (
            <>
              <IconComponent
                className={`${styles.message__statusIcon} ${
                  styles[`message__statusIcon--${status}`]
                }`}
                iconName="exclamationMarkInCircle"
              />
              <span className={styles.message__id}>
                {translate('numberN', { number: id })}
              </span>
            </>
          )}
        </div>
        {date && time && (
          <div
            className={`${styles.message__group} ${styles['message__group--2']}`}
          >
            <div className={styles['message__group--date']}>
              <IconComponent
                className={`${styles.message__icon}`}
                iconName="calendar"
              />
              <span className={styles.message__date}>{date}</span>
            </div>
            <div className={styles['message__group--time']}>
              <IconComponent
                className={`${styles.message__icon}`}
                iconName="clock"
              />
              <span className={styles.message__time}>{time}</span>
            </div>
          </div>
        )}
      </div>
      <hr />
      <div className={`${styles.message__line} ${styles['message__line--3']}`}>
        <p className={styles.message__content}>{content}</p>
      </div>
    </div>
  )
}

export default Message
