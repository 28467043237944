export default {
  DASHBOARD: {
    HREF: '/dashboard',
    TEXT: 'Dashboard',
  },
  DRYING: {
    HREF: '/drying',
    TEXT: 'Trocknung',
  },
  ENERGY_MANAGEMENT: {
    HREF: '/energy_management',
    TEXT: 'Energie-Management',
  },
  LOGIN: {
    HREF: '/login',
    TEXT: 'Login',
  },
  MESSAGE_LIST: {
    HREF: '/message_list',
    TEXT: 'Meldeliste',
  },
  PRODUCTION_QUANTITIES: {
    HREF: '/production_quantities',
    TEXT: 'Produktionsmengen',
  },
  SIGN_OUT: {
    HREF: '/logout',
    TEXT: 'Abmelden',
  },
  SORTING_MACHINES: {
    HREF: '/sorting_machines',
    TEXT: 'Sortiermaschinen',
  },
  START: {
    HREF: '/',
    TEXT: 'Start',
  },
}
