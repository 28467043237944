import useCurrentDate from 'hooks/useCurrentDate'
import usePlsTranslation from 'hooks/usePlsTranslation'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

interface IUseTimePeriodSelectorProps {
  selectedTime: string
  selectedTimePeriod: string
  setSelectedTime: Dispatch<SetStateAction<string>>
  setSelectedTimePeriod: Dispatch<SetStateAction<string>>
  styles: {
    [key: string]: string
  }
}

export default ({
  selectedTime,
  selectedTimePeriod,
  setSelectedTime,
  setSelectedTimePeriod,
  styles,
}: IUseTimePeriodSelectorProps) => {
  const { translate } = usePlsTranslation()
  const location = useLocation()

  const TIME_PERIOD_DIRECTION = {
    NEXT: 'NEXT',
    PREV: 'PREV',
  }
  const timePeriods = [
    { name: translate('timePeriod.day') },
    // { disabled: true, name: translate(`timePeriod.week`) },
    { name: translate('timePeriod.month') },
    { name: translate('timePeriod.year') },
  ]

  useEffect(() => {
    const { year, month, day } = useCurrentDate()
    setSelectedTime(`${year}-${month}-${day}`)
  }, [location])

  const handleInputChange = ({
    target: { value },
  }: {
    target: { value: string }
  }) => {
    setSelectedTime(value)
  }
  const handleTimePeriodArrowClick = (direction: string) => {
    let value = ''
    const newDateOfSelectedTime = new Date(selectedTime)
    const newDateOfToday = new Date()
    const directionMultiplier = direction === TIME_PERIOD_DIRECTION.NEXT ? 1 : -1
    switch (selectedTimePeriod) {
      case translate('timePeriod.day'): {
        let newDay
        if (direction === TIME_PERIOD_DIRECTION.NEXT) {
          newDay = moment(selectedTime).add(1, 'day')
        } else {
          newDay = moment(selectedTime).subtract(1, 'day')
        }
        value = `${newDateOfSelectedTime.getFullYear()}-${`0${
          newDateOfSelectedTime.getMonth() + 1
        }`.slice(-2)}-${`0${newDateOfSelectedTime.getDate()}`.slice(-2)}`

        if (!(newDay > moment())) {
          value = newDay.format('YYYY-MM-DD')
        } else {
          value = moment(selectedTime).format('YYYY-MM-DD')
        }
        break
      }
      case translate('timePeriod.week'): {
        value = `${newDateOfSelectedTime.getFullYear()}-${`0${
          newDateOfSelectedTime.getMonth() + 1
        }`.slice(-2)}-${`0${newDateOfSelectedTime.getDate()}`.slice(-2)}`
        break
      }
      case translate('timePeriod.month'): {
        const newMonth = newDateOfSelectedTime.getMonth() + 1 * directionMultiplier
        if (
          !(
            newMonth > newDateOfToday.getMonth()
            && newDateOfSelectedTime.getFullYear() === newDateOfToday.getFullYear()
          )
        ) {
          newDateOfSelectedTime.setMonth(newMonth)
        }
        value = `${newDateOfSelectedTime.getFullYear()}-${`0${
          newDateOfSelectedTime.getMonth() + 1
        }`.slice(-2)}`
        break
      }
      case translate('timePeriod.year'): {
        const newYear = newDateOfSelectedTime.getFullYear() + 1 * directionMultiplier
        if (!(newYear > newDateOfToday.getFullYear())) {
          newDateOfSelectedTime.setFullYear(newYear)
        }
        value = `${newDateOfSelectedTime.getFullYear()}`
        break
      }
      default: {
        value = ''
      }
    }
    setSelectedTime(value)
  }
  const handleTimePeriodClick = ({
    name,
    disabled,
  }: {
    name: string
    disabled?: boolean
  }) => {
    if (!disabled) {
      setSelectedTimePeriod(() => {
        const { year, month, day } = useCurrentDate()
        switch (name) {
          case translate('timePeriod.day'): {
            setSelectedTime(`${year}-${month}-${day}`)
            break
          }
          case translate('timePeriod.month'): {
            setSelectedTime(`${year}-${month}`)
            break
          }
          case translate('timePeriod.year'): {
            setSelectedTime(`${year}`)
            break
          }
          default: {
            setSelectedTime('')
          }
        }
        return name
      })
    }
  }
  const TimePeriodSelector = () => {
    const { year, month, day } = useCurrentDate()
    switch (selectedTimePeriod) {
      case translate('timePeriod.day'): {
        return (
          <input
            className={styles.timePeriodSelector__input}
            max={`${year}-${month}-${day}`}
            onChange={handleInputChange}
            type="date"
            value={selectedTime}
          />
        )
      }
      case translate('timePeriod.week'): {
        return (
          <input
            className={styles.timePeriodSelector__input}
            onChange={handleInputChange}
            type="week"
            value={selectedTime}
          />
        )
      }
      case translate('timePeriod.month'): {
        return (
          <input
            className={styles.timePeriodSelector__input}
            max={`${year}-${month}`}
            onChange={handleInputChange}
            type="month"
            value={selectedTime}
          />
        )
      }
      case translate('timePeriod.year'): {
        return (
          <input
            className={`${styles.timePeriodSelector__input} ${styles['timePeriodSelector__input--year']}`}
            max={`${year}`}
            onChange={handleInputChange}
            type="number"
            value={selectedTime}
          />
        )
      }
      default: {
        return (
          <input
            className={styles.timePeriodSelector__input}
            onChange={handleInputChange}
            type="number"
            value={selectedTime}
          />
        )
      }
    }
  }

  return {
    handleTimePeriodArrowClick,
    handleTimePeriodClick,
    selectedTimePeriod,
    TimePeriodSelector,
    timePeriods,
    TIME_PERIOD_DIRECTION,
  }
}
