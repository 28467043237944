import Box from 'components/Box/Box'
import { BOX_WIDTH } from 'constants/box'
import React from 'react'
import QuantityIndicationInterface from './QuantityIndication.interface'

const QuantityIndication = ({
  name,
  selectedTime = '',
  selectedTimePeriod = '',
}: QuantityIndicationInterface) => (
  <Box
    name={name}
    selectedTime={selectedTime}
    selectedTimePeriod={selectedTimePeriod}
    width={BOX_WIDTH.FULL}
  />
)

export default QuantityIndication
