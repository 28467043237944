import Card from 'components/Card/Card'
import SortingMachine from 'components/SortingMachine/SortingMachine'
import useSortingMachinesChartBox from 'hooks/useSortingMachinesChartBox'
import React from 'react'
import SortingMachinesInterface from './SortingMachines.interface'
import styles from './SortingMachines.module.scss'

const SortingMachines = (props: SortingMachinesInterface) => {
  const { deviceID } = props
  // eslint-disable-next-line max-len
  const { renderSortingMachinesChartBoxProps, sortingMachines, loading } =
    useSortingMachinesChartBox({
      deviceID,
    })
  const { title, titleDetails } = renderSortingMachinesChartBoxProps()
  return (
    <Card title={title} titleDetails={titleDetails}>
      <div className={styles.cardsGrid}>
        {sortingMachines.map((sortingMachine) => (
          <div className={styles.card} key={sortingMachine.text}>
            <SortingMachine
              iconModifier={sortingMachine.iconModifier}
              key={sortingMachine.text}
              text={sortingMachine.text}
              value={sortingMachine.value}
              loading={loading}
            />
          </div>
        ))}
      </div>
    </Card>
  )
}

export default SortingMachines
