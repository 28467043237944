import { gql, useQuery } from '@apollo/client'
import IconComponent from 'components/Icon/Icon'
import CHART_COLORS from 'constants/chartColors'
import CHART_TYPES from 'constants/chartTypes'
import MENU from 'constants/menu'
import usePlsTranslation from 'hooks/usePlsTranslation'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { QUERY_FOR_OVERVIEW_RUNTIME_ANALYSIS } from 'graphql/queries'
import { QUERY_FOR_OVERVIEW, DataIndex } from 'graphql/overview'
import { getConfigItemValue } from 'utils/config'
import styles from './useChartBox.module.scss'

interface IUseChartBoxProps {
  deviceId: number
}

interface DataValue {
  value: number
  timestamp: string
}

interface BulkDataValue {
  bulkData: { values: DataValue[] }[]
}

interface ChartConfig {
  alarm: {
    warningUpperSet?: number
    warningLowerSet?: number
    errorUpperSet?: number
    errorLowerSet?: number
    max: number
    min: number
  }
  maxValue: number
  name: string
  onClick: () => void
  unit: string
  value: number
  warning: {
    min: number
    max: number
  }
}

export default ({ deviceId }: IUseChartBoxProps) => {
  const { translate } = usePlsTranslation()
  const history = useNavigate()
  const runtimeFromDate = moment().subtract('1', 'days').toDate()
  const toDate = moment().add('1', 'day').format() // do this to be able to do polling with same variables
  const pollInterval = getConfigItemValue('dashboard.pollInterval')
  const prepareQueryVariables = () =>
    useMemo(() => {
      return {
        deviceId: String(deviceId),
        to: toDate,
      }
    }, [deviceId])

  const prepareRuntimeQueryVariables = () =>
    useMemo(() => {
      return {
        deviceId: String(deviceId),
        from: runtimeFromDate,
        to: toDate,
      }
    }, [deviceId])

  const { data, loading } = useQuery(
    gql`
      ${QUERY_FOR_OVERVIEW}
    `,
    {
      variables: prepareQueryVariables(),
      ...(pollInterval && { pollInterval }),
      notifyOnNetworkStatusChange: true,
    },
  )

  const { data: runtimeData, loading: runtimeLoading } = useQuery(
    gql`
      ${QUERY_FOR_OVERVIEW_RUNTIME_ANALYSIS}
    `,
    {
      variables: prepareRuntimeQueryVariables(),
      ...(pollInterval && { pollInterval }),
      notifyOnNetworkStatusChange: true,
    },
  )

  const renderOverviewChartBoxProps = () => {
    const runtimeChartData: any[] = []
    const runtimeChartTooltipData: any[] = []
    let lastTime = ''
    if (runtimeData) {
      for (let i = 0; i < runtimeData?.running[0]?.values.length; i += 1) {
        const currentTimestamp = +new Date(
          runtimeData?.running[0]?.values[i]?.timestamp,
        )

        if (currentTimestamp > +runtimeFromDate) {
          const runningData = runtimeData?.running[0]?.values[i]
          let value = +new Date(runningData?.timestamp) - +runtimeFromDate
          if (i > 0) {
            value =
              +new Date(runningData?.timestamp) -
              +new Date(runtimeData?.running[0]?.values[i - 1].timestamp)
          }
          runtimeChartData?.push({
            color: runningData?.value
              ? CHART_COLORS.COLOR_1
              : CHART_COLORS.COLOR_2,
            data: [{ value }],
            name: runningData?.value
              ? translate('materialFeedOff')
              : translate('materialFeedOn'),
            stack: 'category',
            type: CHART_TYPES.BAR,
          })
          runtimeChartTooltipData?.push({
            start: +new Date(runningData?.timestamp) - value,
            end: +new Date(runningData?.timestamp),
          })
          if (i === runtimeData?.running[0]?.values.length - 1) {
            value = +new Date() - +new Date(runningData?.timestamp)
            runtimeChartData?.push({
              color: runningData?.value
                ? CHART_COLORS.COLOR_2
                : CHART_COLORS.COLOR_1,
              data: [{ value }],
              name: runningData?.value
                ? translate('materialFeedOff')
                : translate('materialFeedOn'),
              stack: 'category',
              type: CHART_TYPES.BAR,
            })
            runtimeChartTooltipData?.push({
              start: +new Date(runningData?.timestamp),
              end: +new Date(),
            })
          }
        }
      }

      if (
        runtimeChartData?.length > 0 &&
        runtimeChartData[runtimeChartData?.length - 1].data?.length > 0
      ) {
        const diff = moment.duration(
          runtimeChartData[runtimeChartData?.length - 1].data[0]?.value,
        )
        lastTime = `${diff.hours()}h ${diff.minutes()}min`
      }
    }
    return {
      hideXAxisLabel: true,
      legend: {
        show: true,
        top: 'top',
      },
      series: {
        data: runtimeChartData,
        loading: !runtimeChartData?.length && runtimeLoading,
      },
      title: translate(getConfigItemValue('dashboard.overview.translation')),
      titleDetails: (
        <div className={styles.useChartBox__overviewTitleDetails}>
          <span className={styles['useChartBox__overviewTitleDetails--key']}>
            {runtimeData &&
            runtimeData?.running[0]?.values[
              runtimeData?.running[0]?.values.length - 1
            ]?.value
              ? translate('systemRunningSince')
              : translate('systemStandsSince')}
          </span>
          <div
            className={
              styles['useChartBox__overviewTitleDetails--valueContainer']
            }
          >
            <IconComponent
              className={styles.useChartBox__icon}
              iconName="clock"
            />
            <span
              className={styles['useChartBox__overviewTitleDetails--value']}
            >
              {lastTime}
            </span>
          </div>
        </div>
      ),
      xAxisType: 'value',
      yAxisType: 'category',
      tooltipData: runtimeChartTooltipData,
      tooltipRenderer: (tooltipData: any) => `<div>
          <p>
            ${translate('start')}: ${moment(
        new Date(tooltipData?.start),
      ).format('HH:mm')}
          </p>
          <p>
            ${translate('end')}: ${moment(new Date(tooltipData?.end)).format(
        'HH:mm',
      )}
          </p>
        </div>`,
    }
  }

  const getValue = (dataObject: BulkDataValue, index: DataIndex): number =>
    dataObject?.bulkData?.[index]?.values?.[0]?.value ?? 0

  const simpleChartsConfig: ChartConfig[] = []

  if (getConfigItemValue('dashboard.overview.input.enabled')) {
    simpleChartsConfig.push({
      alarm: {
        warningUpperSet: getValue(data, DataIndex.INPUT_WARNING_UPPER_ACTIVE),
        warningLowerSet: getValue(data, DataIndex.INPUT_WARNING_LOWER_ACTIVE),
        errorUpperSet: getValue(data, DataIndex.INPUT_ALARM_UPPER_ACTIVE),
        errorLowerSet: getValue(data, DataIndex.INPUT_ALARM_LOWER_ACTIVE),
        max: getValue(data, DataIndex.INPUT_ALARM_UPPER),
        min: getValue(data, DataIndex.INPUT_ALARM_LOWER),
      },
      maxValue: getValue(data, DataIndex.INPUT_ALARM_UPPER),
      name: translate(
        getConfigItemValue('dashboard.overview.input.translation'),
      ),
      onClick: () => history(MENU.PRODUCTION_QUANTITIES.HREF),
      unit: getConfigItemValue('dashboard.overview.input.unit'),
      value: getValue(data, DataIndex.INPUT_MEASUREMENT),
      warning: {
        min: getValue(data, DataIndex.INPUT_WARNING_LOWER),
        max: getValue(data, DataIndex.INPUT_WARNING_UPPER),
      },
    })
  }
  // Processing Rate
  if (getConfigItemValue('dashboard.overview.processingRate.enabled')) {
    simpleChartsConfig.push({
      alarm: {
        max: 100, // Assuming 100 is a fixed upper limit for processing rate
        min: getValue(data, DataIndex.PROCESSING_RATE_ALARM_LOWER),
      },
      maxValue: 100, // Assuming 100 is a fixed upper value for processing rate visualization
      name: translate(
        getConfigItemValue('dashboard.overview.processingRate.translation'),
      ),
      onClick: () => history(MENU.PRODUCTION_QUANTITIES.HREF),
      unit: getConfigItemValue('dashboard.overview.processingRate.unit'),
      value: getValue(data, DataIndex.PROCESSING_RATE_MEASUREMENT),
      warning: {
        max: 100, // Assuming 100 is a fixed upper warning limit for processing rate
        min: getValue(data, DataIndex.PROCESSING_RATE_WARNING_LOWER),
      },
    })
  }

  // Burner Performance
  if (getConfigItemValue('dashboard.overview.burnerPerformance.enabled')) {
    simpleChartsConfig.push({
      alarm: {
        max: getValue(data, DataIndex.BURNER_PERFORMANCE_ALARM_UPPER),
        min: getValue(data, DataIndex.BURNER_PERFORMANCE_ALARM_LOWER),
      },
      maxValue: getValue(data, DataIndex.BURNER_PERFORMANCE_ALARM_UPPER),
      name: translate(
        getConfigItemValue('dashboard.overview.burnerPerformance.translation'),
      ),
      onClick: () => history(MENU.DRYING.HREF),
      unit: getConfigItemValue('dashboard.overview.burnerPerformance.unit'),
      value: getValue(data, DataIndex.BURNER_PERFORMANCE_MEASUREMENT),
      warning: {
        max: getValue(data, DataIndex.BURNER_PERFORMANCE_WARNING_UPPER),
        min: getValue(data, DataIndex.BURNER_PERFORMANCE_WARNING_LOWER),
      },
    })
  }

  // System Performance
  if (getConfigItemValue('dashboard.overview.systemPerformance.enabled')) {
    simpleChartsConfig.push({
      alarm: {
        max: getValue(data, DataIndex.PERFORMANCE_ALARM_UPPER),
        min: 0, // Assuming 0 as a fixed lower limit for system performance
      },
      maxValue: getValue(data, DataIndex.PERFORMANCE_ALARM_UPPER),
      name: translate(
        getConfigItemValue('dashboard.overview.systemPerformance.translation'),
      ),
      onClick: () => history(MENU.ENERGY_MANAGEMENT.HREF),
      unit: getConfigItemValue('dashboard.overview.systemPerformance.unit'),
      value: getValue(data, DataIndex.PERFORMANCE_MEASUREMENT),
      warning: {
        max: getValue(data, DataIndex.PERFORMANCE_WARNING_UPPER),
        min: 0, // Assuming 0 as a fixed lower warning limit for system performance
      },
    })
  }

  // Air Pressure
  if (getConfigItemValue('dashboard.overview.airPressure.enabled')) {
    simpleChartsConfig.push({
      alarm: {
        max: getValue(data, DataIndex.COMPRESSED_AIR_QUANTITY_ALARM_UPPER),
        min: getValue(data, DataIndex.COMPRESSED_AIR_QUANTITY_ALARM_LOWER),
      },
      maxValue: getValue(data, DataIndex.COMPRESSED_AIR_QUANTITY_ALARM_UPPER),
      name: translate(
        getConfigItemValue('dashboard.overview.airPressure.translation'),
      ),
      onClick: () => history(MENU.ENERGY_MANAGEMENT.HREF),
      unit: getConfigItemValue('dashboard.overview.airPressure.unit'),
      value: getValue(data, DataIndex.COMPRESSED_AIR_QUANTITY_MEASUREMENT),
      warning: {
        max: getValue(data, DataIndex.COMPRESSED_AIR_QUANTITY_WARNING_UPPER),
        min: getValue(data, DataIndex.COMPRESSED_AIR_QUANTITY_WARNING_LOWER),
      },
    })
  }

  return {
    renderOverviewChartBoxProps,
    simpleChartsConfig,
    loading: loading && !data,
    runtimeLoading,
  }
}
