import MENU from 'constants/menu'
import usePlsTranslation from 'hooks/usePlsTranslation'
import { useLocation } from 'react-router-dom'

interface IUseTopBarProps {
  handleGoBackClick: () => void
  toggleMenuVisibility: () => void
}

export default ({
  handleGoBackClick,
  toggleMenuVisibility,
}: IUseTopBarProps) => {
  const { translate } = usePlsTranslation()
  const { pathname } = useLocation()
  const displayTopBarIcon = pathname !== MENU.DASHBOARD.HREF
  const topBarIconName = pathname === MENU.DASHBOARD.HREF ? 'bars' : 'angleLeft'

  const onClick = () => (pathname === MENU.DASHBOARD.HREF
    ? toggleMenuVisibility()
    : handleGoBackClick())
  const renderCurrentPageName = () => {
    switch (pathname) {
      case MENU.DASHBOARD.HREF: {
        return translate('menuItem.dashboard')
      }
      case MENU.DRYING.HREF: {
        return translate('menuItem.drying')
      }
      case MENU.ENERGY_MANAGEMENT.HREF: {
        return translate('menuItem.energyManagement')
      }
      case MENU.MESSAGE_LIST.HREF: {
        return translate('menuItem.messageList')
      }
      case MENU.PRODUCTION_QUANTITIES.HREF: {
        return translate('menuItem.productionQuantities')
      }
      case MENU.SORTING_MACHINES.HREF: {
        return translate('menuItem.sortingMachines')
      }
      default: {
        return ''
      }
    }
  }

  return {
    displayTopBarIcon,
    onClick,
    renderCurrentPageName,
    topBarIconName,
  }
}
