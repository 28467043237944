import { gql, useQuery } from '@apollo/client'
import TitleDetailsComponent from 'components/TitleDetails/TitleDetails'
import CHART_COLORS from 'constants/chartColors'
import CHART_TYPES from 'constants/chartTypes'
import { QUERY_FOR_BURNER_PERFORMANCE } from 'graphql/queries'
import usePlsTranslation from 'hooks/usePlsTranslation'
import formatNumber from 'utils/common'
import React, { useMemo } from 'react'
import {
  fillChartFutureValuesWithZeros,
  formatXAxis,
  renderColumnsQuantity,
  renderTimeRange,
  getQueryRefetchParams,
} from 'utils/charts'
import moment from 'moment'
import { getConfigItemValue } from 'utils/config'

interface IUseChartBoxProps {
  deviceId: number
  selectedTime: string
}

export default ({ deviceId, selectedTime }: IUseChartBoxProps) => {
  const { translate } = usePlsTranslation()
  const { from, to } = renderTimeRange(selectedTime)
  const prepareQuery = (queryString: string) =>
    useMemo(() => {
      const segments = selectedTime.split('-')
      const aggregation = segments.length === 3 ? 'TimeWeightedAverage' : 'Max'
      const preparedQueryString = queryString
        .replace('{{{aggregation}}}', aggregation)
        .replace(
          '{{{calculationTimeRange}}}',
          String(renderColumnsQuantity(selectedTime)),
        )
        .replace('{{{deviceId}}}', String(deviceId))
        .replace('{{{from}}}', moment(from).toISOString())
        .replace('{{{to}}}', moment(to).toISOString())
      return gql`
        ${preparedQueryString}
      `
    }, [deviceId, from, queryString, to])
  const prepareData = (queryData: {
    calculateTrend: { values: { timestamp: string; value: number }[] }[]
  }) => {
    const arrayWithData = queryData?.calculateTrend?.[0]?.values || []
    const preparedData = arrayWithData.map((details) => {
      return {
        value: Math.round(details.value * 100) / 100,
        xAxisData: formatXAxis({ selectedTime, timestamp: details.timestamp }),
      }
    })
    return fillChartFutureValuesWithZeros({ preparedData, selectedTime })
  }
  const renderBurnerPerformanceChartBoxProps = ({
    selectedTimePeriod,
    unit,
  }: {
    selectedTimePeriod: string
    unit: string
  }) => {
    // eslint-disable-next-line max-len
    const { data: burnerPerformanceData, loading: burnerPerformanceLoading } =
      useQuery(
        prepareQuery(QUERY_FOR_BURNER_PERFORMANCE),
        getQueryRefetchParams(selectedTime, 0),
      )
    const preparedData = prepareData(burnerPerformanceData)
    const sum = Math.round(
      preparedData.map((data) => data.value).reduce((a, b) => +a + +b, 0),
    )
    const avg =
      Math.round(
        (sum * 100) / preparedData.filter((data) => !!data.value).length,
      ) / 100
    return {
      series: {
        data: [
          {
            color: CHART_COLORS.COLOR_1,
            data: preparedData,
            name: translate(
              getConfigItemValue('drying.burnerPerformance.translation'),
            ),
            type:
              selectedTimePeriod === translate('timePeriod.month') ||
              selectedTimePeriod === translate('timePeriod.year')
                ? CHART_TYPES.BAR
                : CHART_TYPES.LINE,
            // TODO: Performance - move above condition
            // (month or year) to new constant "displayBarChart"
            // in every file
          },
        ],
        loading: burnerPerformanceLoading,
      },
      title: translate(
        getConfigItemValue('drying.burnerPerformance.translation'),
      ),
      titleDetails: (
        <TitleDetailsComponent
          iconName="average"
          value={`${formatNumber(avg)} ${unit}`}
        />
      ),
    }
  }

  return {
    renderBurnerPerformanceChartBoxProps,
  }
}
