import { gql, useQuery } from '@apollo/client'
import TitleDetailsComponent from 'components/TitleDetails/TitleDetails'
import CHART_COLORS from 'constants/chartColors'
import CHART_TYPES from 'constants/chartTypes'
import {
  QUERY_FOR_DRYING_PREV_DAILY,
  QUERY_FOR_INPUT_PREV_DAILY,
  QUERY_FOR_OUTPUT_PREV_DAILY,
  QUERY_FOR_DRYING_CURRENT_DAILY,
  QUERY_FOR_INPUT_CURRENT_DAILY,
  QUERY_FOR_OUTPUT_CURRENT_DAILY,
} from 'graphql/queries'
import usePlsTranslation from 'hooks/usePlsTranslation'
import React, { useMemo, useEffect, useState } from 'react'
import {
  fillChartFutureValuesWithZeros,
  formatXAxis,
  renderColumnsQuantity,
  renderTimeRange,
  timeRangeIncludesToday,
} from 'utils/charts'
import formatNumber from 'utils/common'
import moment from 'moment'
import { getConfigItemValue } from 'utils/config'

interface IUseChartBoxProps {
  deviceId: number
  selectedTime: string
  selectedTimePeriod: string
}

interface AxisData {
  value: number
  xAxisData: string
}

export default ({
  deviceId,
  selectedTime,
  selectedTimePeriod,
}: IUseChartBoxProps) => {
  const { translate } = usePlsTranslation()
  const { from, to } = renderTimeRange(selectedTime)
  const includesToday = timeRangeIncludesToday(selectedTime)
  const outputElements = getConfigItemValue(
    'productionQuantities.quantityIndications.outputElements',
  )
  const inputElement = getConfigItemValue(
    'productionQuantities.quantityIndications.inputElements',
  )?.[0]

  const dryingElement = getConfigItemValue(
    'productionQuantities.quantityIndications.dryingElements',
  )?.[0]

  const numberOfOutputs = outputElements.length

  const [preparedInputData, setPreparedInputData] = useState<AxisData[]>([])
  const [preparedOutputData, setPreparedOutputData] = useState<AxisData[][]>([])
  const [preparedDryingData, setPreparedDryingData] = useState<AxisData[]>([])

  const prepareQuery = (queryString: string) =>
    useMemo(() => {
      const segments = selectedTime.split('-')
      const aggregation = segments.length === 3 ? 'TimeWeightedAverage' : 'Max'
      const preparedQueryString = queryString
        .replaceAll('{{{aggregation}}}', aggregation)
        .replaceAll(
          '{{{calculationTimeRange}}}',
          String(renderColumnsQuantity(selectedTime)),
        )
        .replaceAll('{{{deviceId}}}', String(deviceId))
        .replaceAll('{{{from}}}', moment(from).toISOString())
        .replaceAll('{{{to}}}', moment(to).add(1, 'day').toISOString())
      return gql`
        ${preparedQueryString}
      `
    }, [deviceId, from, queryString, to])

  const prepareQueryToday = (queryString: string) =>
    useMemo(() => {
      const preparedQueryString = queryString
        .replaceAll('{{{deviceId}}}', String(deviceId))
        .replaceAll('{{{from}}}', moment().startOf('day').toISOString())
        .replaceAll('{{{to}}}', moment().endOf('day').toISOString())
      return gql`
        ${preparedQueryString}
      `
    }, [deviceId, from, queryString, to])

  const prepareData = (queryData: { timestamp: string; value: number }[]) => {
    const arrayWithData = queryData || []
    const preparedData = arrayWithData.map((details) => {
      return {
        value: Math.round(details.value * 100) / 100,
        xAxisData: formatXAxis({ selectedTime, timestamp: details.timestamp }),
      }
    })
    return fillChartFutureValuesWithZeros({ preparedData, selectedTime })
  }

  const { data: inputDataDaily, loading: inputDailyLoading } = useQuery(
    prepareQuery(QUERY_FOR_INPUT_PREV_DAILY),
    {
      fetchPolicy: includesToday ? 'network-only' : 'cache-first',
    },
  )

  const { data: inputDataToday, loading: inputTodayLoading } = useQuery(
    prepareQueryToday(QUERY_FOR_INPUT_CURRENT_DAILY),
    {
      fetchPolicy: includesToday ? 'network-only' : 'cache-first',
    },
  )

  const { data: dryingDataDaily, loading: dryingDailyLoading } = useQuery(
    prepareQuery(QUERY_FOR_DRYING_PREV_DAILY),
    {
      fetchPolicy: includesToday ? 'network-only' : 'cache-first',
    },
  )

  const { data: dryingDataToday, loading: dryingTodayLoading } = useQuery(
    prepareQueryToday(QUERY_FOR_DRYING_CURRENT_DAILY),
    {
      fetchPolicy: includesToday ? 'network-only' : 'cache-first',
    },
  )

  const { data: outputDataDaily, loading: outputDailyLoading } = useQuery(
    prepareQuery(QUERY_FOR_OUTPUT_PREV_DAILY(numberOfOutputs)),
    {
      fetchPolicy: includesToday ? 'network-only' : 'cache-first',
    },
  )

  const { data: outputDataToday, loading: outputTodayLoading } = useQuery(
    prepareQueryToday(QUERY_FOR_OUTPUT_CURRENT_DAILY(numberOfOutputs)),
    {
      fetchPolicy: includesToday ? 'network-only' : 'cache-first',
    },
  )

  useEffect(() => {
    const inputDaily: { value: number; timestamp: string }[] =
      inputDataDaily?.input
    if (
      includesToday &&
      !inputTodayLoading &&
      !inputDailyLoading &&
      inputDataToday
    ) {
      const inputToday: { value: number; timestamp: string }[] =
        inputDataToday.input
      // fake input data timing to always apply the prev day logic
      inputToday[0].timestamp = moment()
        .add(1, 'days')
        .startOf('day')
        .toISOString()
      const data = inputDaily.concat(inputToday)
      setPreparedInputData(prepareData(data))
    } else if (!includesToday && !inputDailyLoading) {
      setPreparedInputData(prepareData(inputDaily))
    }
  }, [inputDataToday, inputDataDaily])

  useEffect(() => {
    if (
      includesToday &&
      !outputTodayLoading &&
      !outputDailyLoading &&
      outputDataToday
    ) {
      const data: any = []
      // fake input data timing to always apply the prev day logic
      outputElements.forEach((_: any, index: number) => {
        const output: { value: number; timestamp: string }[] =
          outputDataToday[`output_${index + 1}`]
        const outputDaily: { value: number; timestamp: string }[] =
          outputDataDaily[`output_${index + 1}`]
        // catch empty array
        if (!output || output.length === 0) {
          output.push({
            timestamp: "",
            value: 0
          })
        }
        output[0].timestamp = moment()
          .add(1, 'days')
          .startOf('day')
          .toISOString()
        data.push(prepareData(outputDaily.concat(output)))
      })

      // fake input data timing to always apply the prev day logic
      setPreparedOutputData(data)
    } else if (!includesToday && !outputDailyLoading) {
      const data: any = []
      outputElements.forEach((_: any, index: number) => {
        const output: { value: number; timestamp: string }[] =
          outputDataDaily[`output_${index + 1}`]
        data.push(prepareData(output))
      })
      setPreparedOutputData(data)
    }
  }, [outputDataToday, outputDataDaily])

  useEffect(() => {
    const dryingDaily: { value: number; timestamp: string }[] =
      dryingDataDaily?.drying
    if (
      includesToday &&
      !dryingTodayLoading &&
      !dryingDailyLoading &&
      dryingDataToday
    ) {
      const dryingToday: { value: number; timestamp: string }[] =
        dryingDataToday.drying
      // fake input data timing to always apply the prev day logic
      dryingToday[0].timestamp = moment()
        .add(1, 'days')
        .startOf('day')
        .toISOString()
      const data = dryingDaily.concat(dryingToday)
      setPreparedDryingData(prepareData(data))
    } else if (!includesToday && !dryingDailyLoading) {
      setPreparedDryingData(prepareData(dryingDaily))
    }
  }, [dryingDataToday, dryingDataDaily])

  const renderProcessingRateChartBoxProps = ({ unit }: { unit: string }) => {
    const prepare = (inputValues: AxisData[], outputValues: AxisData[]) =>
      outputValues.map((outputValue, index: number) => {
        return {
          ...outputValue,
          value:
            Math.round((inputValues[index]?.value / outputValue?.value) * 10000) /
            100,
        }
      })
    let sum = 0
    let avg = 0
    let preparedData: AxisData[] = []
    if (preparedOutputData.length && preparedInputData) {
      // sum up all output values
      const cumulatedOutputData: AxisData[] = preparedOutputData[0].map(
        (_, dataIndex: number) => {
          let outputSum = 0
          outputElements.forEach((element: any, elementIndex: number) => {
            outputSum += preparedOutputData[elementIndex][dataIndex]?.value || 0
          })
          return {
            xAxisData: preparedOutputData[0][dataIndex].xAxisData,
            value: outputSum,
          }
        },
      )
      preparedData = prepare(cumulatedOutputData, preparedInputData)
      sum = preparedData
        // eslint-disable-next-line max-len
        .map((data: AxisData) => (Number.isNaN(data.value) ? 0 : data.value))
        .reduce((a, b) => +a + +b, 0)
      avg =
        Math.round(
          (sum * 100) /
            preparedData.filter((data: AxisData) => !Number.isNaN(data.value))
              .length,
        ) / 100
    }

    return {
      series: {
        data: [
          {
            color: CHART_COLORS.COLOR_4,
            data: preparedData,
            name: translate('chart.processingRate'),
            type:
              selectedTimePeriod === translate('timePeriod.month') ||
              selectedTimePeriod === translate('timePeriod.year')
                ? CHART_TYPES.BAR
                : CHART_TYPES.LINE,
          },
        ],
        loading:
          inputDailyLoading ||
          outputDailyLoading ||
          dryingDailyLoading ||
          inputTodayLoading ||
          outputTodayLoading ||
          dryingTodayLoading,
      },
      title: translate(
        getConfigItemValue('productionQuantities.processingRate.translation'),
      ),
      titleDetails: (
        <TitleDetailsComponent
          iconName="average"
          value={`${formatNumber(avg)} ${unit}`}
        />
      ),
    }
  }

  const renderProductionQuantitiesDryingChartBoxProps = ({
    unit,
  }: {
    unit: string
  }) => {
    // since we are only fetching 1 element, this will always be the first [0]
    const dryingToday = dryingDataToday?.drying[0]

    if (dryingToday && selectedTimePeriod === translate('timePeriod.month')) {
      const dryingTodayDate = moment(dryingToday.xAxisData).format('DD.MM')
      // replace array element
      const index = preparedDryingData.findIndex(
        (prep) => prep.xAxisData === dryingTodayDate,
      )
      preparedDryingData[index] = {
        value: dryingToday.value,
        xAxisData: dryingTodayDate,
      }
    }

    const preparedData = fillChartFutureValuesWithZeros({
      preparedData: preparedDryingData,
      selectedTime,
    })
    const sum = Math.round(
      preparedData.map((data) => data.value).reduce((a, b) => +a + +b, 0),
    )
    return {
      series: {
        data: [
          {
            color: dryingElement.color || CHART_COLORS.COLOR_2,
            data: preparedData,
            name: translate('chart.drying'),
            type:
              selectedTimePeriod === translate('timePeriod.month') ||
              selectedTimePeriod === translate('timePeriod.year')
                ? CHART_TYPES.BAR
                : CHART_TYPES.LINE,
          },
        ],
        loading: dryingDailyLoading || dryingTodayLoading,
      },
      title: translate('chart.drying'),
      titleDetails: (
        <TitleDetailsComponent
          iconName="sum"
          value={`${formatNumber(sum)} ${unit}`}
        />
      ),
    }
  }
  const renderProductionQuantitiesInputChartBoxProps = ({
    unit,
  }: {
    unit: string
  }) => {
    const preparedData = fillChartFutureValuesWithZeros({
      preparedData: preparedInputData,
      selectedTime,
    })

    const sum = Math.round(
      preparedData.map((data) => data.value).reduce((a, b) => +a + +b, 0),
    )

    return {
      series: {
        data: [
          {
            color: inputElement.color || CHART_COLORS.COLOR_1,
            data: preparedData,
            name: translate(
              getConfigItemValue(
                'productionQuantities.quantityIndications.inputElements.0.translation',
              ),
            ),
            type:
              selectedTimePeriod === translate('timePeriod.month') ||
              selectedTimePeriod === translate('timePeriod.year')
                ? CHART_TYPES.BAR
                : CHART_TYPES.LINE,
          },
        ],
        loading: inputDailyLoading || inputTodayLoading,
      },
      title: translate(
        getConfigItemValue(
          'productionQuantities.quantityIndications.inputElements.0.translation',
        ),
      ),
      titleDetails: (
        <TitleDetailsComponent
          iconName="sum"
          value={`${formatNumber(sum)} ${unit}`}
        />
      ),
    }
  }
  const renderProductionQuantitiesOutputChartsBoxProps = ({
    unit,
  }: {
    unit: string
  }) =>
    outputElements.map((element: any, index: number) => {
      // since we are only fetching 1 element, this will always be the first [0]
      const preparedData = fillChartFutureValuesWithZeros({
        preparedData: preparedOutputData.length ? preparedOutputData[index] : [],
        selectedTime,
      })
      const sum = Math.round(
        preparedData.map((data) => data.value).reduce((a, b) => +a + +b, 0),
      )
      return {
        series: {
          data: [
            {
              color: element.color || CHART_COLORS.COLOR_3,
              data: preparedData,
              name: translate(
                getConfigItemValue(
                  `productionQuantities.quantityIndications.outputElements.${index}.translation`,
                ),
              ),
              type:
                selectedTimePeriod === translate('timePeriod.month') ||
                selectedTimePeriod === translate('timePeriod.year')
                  ? CHART_TYPES.BAR
                  : CHART_TYPES.LINE,
            },
          ],
          loading: outputDailyLoading || outputTodayLoading,
        },
        title: translate(
          getConfigItemValue(
            `productionQuantities.quantityIndications.outputElements.${index}.translation`,
          ),
        ),
        titleDetails: (
          <TitleDetailsComponent
            iconName="sum"
            value={`${formatNumber(sum)} ${unit}`}
          />
        ),
      }
    })
  return {
    renderProcessingRateChartBoxProps,
    renderProductionQuantitiesDryingChartBoxProps,
    renderProductionQuantitiesInputChartBoxProps,
    renderProductionQuantitiesOutputChartsBoxProps,
  }
}
