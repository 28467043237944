import { IDeviceProps } from 'hooks/useLayout'
import React from 'react'
import styles from './Dropdown.module.scss'

interface IDropdownProps {
  className?: string
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  options: IDeviceProps[]
  selectedValue?: number
}

const Dropdown = ({
  className,
  onChange,
  options,
  selectedValue,
}: IDropdownProps) => (
  <div className={styles.dropdown__container}>
    <select
      className={`${styles.dropdown__select} ${className}`}
      onChange={onChange}
    >
      {options.map((option) => (
        <option
          key={`${option.id}-${option.text}`}
          selected={+option.id === (selectedValue ? +selectedValue : 0)}
          value={option.id}
        >
          {option.text}
        </option>
      ))}
    </select>
  </div>
)

Dropdown.defaultProps = {
  className: '',
  selectedValue: '0',
}

export default Dropdown
