import { LAST_VALUE_START_DATE } from 'utils/common'

const QUERY_FOR_MESSAGES = (warningCount: number, errorCount: number): string => {
  // Initialize an array to hold all error and warning data objects as strings
  const dataObjects: string[] = []

  // Add error objects to the dataObjects array
  for (let i = 1; i <= errorCount; i += 1) {
    dataObjects.push(`{
      from: "${LAST_VALUE_START_DATE}"
      to: $to
      category: MESSAGE_LIST
      subCategory: MALFUNCTIONS
      variable: MALFUNCTION
      index: 1
      variableIndex: ${i}
      limit: "1"
      order: Descending
    }`)
  }

  // Add warning objects to the dataObjects array
  for (let i = 1; i <= warningCount; i += 1) {
    dataObjects.push(`{
      from: "${LAST_VALUE_START_DATE}"
      to: $to
      category: MESSAGE_LIST
      subCategory: WARNINGS
      variable: WARNING
      index: 1
      variableIndex: ${i}
      limit: "1"
      order: Descending
    }`)
  }

  // Construct the query string, injecting the combined dataObjects array
  const queryString: string = `
    query notifications ($deviceId: String!, $to: DateTime!) {
      bulkData (
        deviceId: $deviceId,
        data: [${dataObjects.join(',')}]
      ) {
        values {
          value
          timestamp
        }
      }
    }
  `

  return queryString
}

export default QUERY_FOR_MESSAGES
