import React from 'react'
import styles from './Button.module.scss'

interface IButtonProps {
  className?: string
  onClick?: () => void
  text: string
  type?: string
}

const ButtonComponent: React.FC<IButtonProps> = ({
  className,
  onClick,
  text,
  type,
}) => (
  <input
    className={`${styles.button} ${className}`}
    onClick={onClick}
    type={type}
    value={text}
  />
)

ButtonComponent.defaultProps = {
  className: '',
  onClick: () => null,
  type: 'button',
}

export default ButtonComponent
