import { gql, useQuery } from '@apollo/client'
import TitleDetailsComponent from 'components/TitleDetails/TitleDetails'
import CHART_TYPES from 'constants/chartTypes'
import {
  QUERY_FOR_ENERGY_DAY,
  QUERY_FOR_ENERGY_PREV_DAILY,
  QUERY_FOR_ENERGY_CURRENT_DAILY
} from 'graphql/queries'
import usePlsTranslation from 'hooks/usePlsTranslation'
import React, { useMemo } from 'react'
import {
  fillChartFutureValuesWithZeros,
  formatXAxis,
  renderColumnsQuantity,
  renderTimeRange,
  renderTodayTimeRange,
  isToday,
  getQueryRefetchParams
} from 'utils/charts'
import moment from 'moment'
import formatNumber from 'utils/common'
import { getConfigItemValue } from 'utils/config'

interface IUseChartBoxProps {
  deviceId: number
  selectedTime: string
}

export default ({ deviceId, selectedTime }: IUseChartBoxProps) => {
  const { translate } = usePlsTranslation()
  const now = new Date()
  const { from, to } = renderTimeRange(selectedTime)
  const { fromToday, toToday } = renderTodayTimeRange()
  const prepareQuery = (queryString: string, usePrevDaily?: boolean) => useMemo(() => {
    const segments = selectedTime.split('-')
    const aggregation = segments.length === 3 ? 'TimeWeightedAverage' : 'Max'
    let preparedQueryString = queryString
      .replaceAll('{{{aggregation}}}', aggregation)
      .replaceAll(
        '{{{calculationTimeRange}}}',
        String(renderColumnsQuantity(selectedTime)),
      )
      .replaceAll('{{{deviceId}}}', String(deviceId))
      .replaceAll('{{{fromToday}}}', moment(fromToday).toISOString())
      .replaceAll('{{{toToday}}}', moment(toToday).toISOString())
    if (usePrevDaily) {
      preparedQueryString = preparedQueryString
        .replaceAll('{{{from}}}', moment(from).add(1, 'day').toISOString())
        .replaceAll('{{{to}}}', moment(to).add(2, 'hour').toISOString())
    } else {
      preparedQueryString = preparedQueryString
        .replaceAll('{{{from}}}', moment(from).toISOString())
        .replaceAll('{{{to}}}', moment(to).toISOString())
    }
    return gql`
        ${preparedQueryString}
      `
  }, [deviceId, from, queryString, to])

  const { data: energyData, loading: energyLoading } = useQuery(
    prepareQuery(QUERY_FOR_ENERGY_DAY), getQueryRefetchParams(selectedTime, 0),
  )

  const { data: energyDataDaily, loading: energyDataDailyLoading } = useQuery(
    prepareQuery(
      isToday(selectedTime)
        ? QUERY_FOR_ENERGY_CURRENT_DAILY
        : QUERY_FOR_ENERGY_PREV_DAILY,
      !isToday(selectedTime)
    ), getQueryRefetchParams(selectedTime, 0)
  )

  const prepareData = (
    queryData: {
      timestamp: string
      value: number
    }[],
  ) => {
    const arrayWithData = queryData || []
    return arrayWithData.map((details) => {
      return {
        value: Math.round(details.value * 100) / 100,
        xAxisData: formatXAxis({ selectedTime, timestamp: details.timestamp }),
      }
    })
  }
  const renderAirPressureChartBoxProps = ({
    selectedTimePeriod,
    unit,
  }: {
    selectedTimePeriod: string
    unit: string
  }) => {
    const preparedAirPressureData = prepareData(energyData?.airPressure?.[0]?.values || [])
    const preparedData = fillChartFutureValuesWithZeros({
      preparedData: preparedAirPressureData,
      selectedTime,
    })

    const sum = energyDataDaily?.airPressure?.[0]?.value || 0
    return {
      series: {
        data: [
          {
            color: getConfigItemValue('energyManagement.airPressure.color'),
            data: preparedData,
            name: translate(getConfigItemValue('energyManagement.airPressure.translation')),
            type:
              selectedTimePeriod === translate('timePeriod.month')
              || selectedTimePeriod === translate('timePeriod.year')
                ? CHART_TYPES.BAR
                : CHART_TYPES.LINE,
          },
        ],
        loading: energyLoading || energyDataDailyLoading,
      },
      title: translate(getConfigItemValue('energyManagement.airPressure.translation')),
      titleDetails: (
        <TitleDetailsComponent
          iconName="sum"
          value={`${formatNumber(sum)} ${unit}`}
        />
      ),
    }
  }
  const renderGasConsumptionChartBoxProps = ({
    selectedTimePeriod,
    unit,
  }: {
    selectedTimePeriod: string
    unit: string
  }) => {
    const preparedPrevDaysGasConsumptionData = prepareData(
      selectedTime.split('-').length === 3
        ? energyData?.gas?.[0]?.values || []
        : energyData?.gas_prev_daily,
    )
    const preparedTodayGasConsumptionData = prepareData(energyData?.gas_today?.[0]?.values || [])
    if (selectedTimePeriod === translate('timePeriod.month')) {
      preparedPrevDaysGasConsumptionData.push({
        value:
          preparedTodayGasConsumptionData[
            preparedTodayGasConsumptionData.length - 1
          ]?.value,
        xAxisData: `${`0${now.getDate()}`.slice(-2)}.${`0${
          now.getMonth() + 1
        }`.slice(-2)}`,
      })
    }
    const preparedData = fillChartFutureValuesWithZeros({
      preparedData: preparedPrevDaysGasConsumptionData,
      selectedTime,
    })

    const sum = energyDataDaily?.gas?.[0]?.value || 0

    return {
      series: {
        data: [
          {
            color: getConfigItemValue('energyManagement.gasConsumption.color'),
            data: preparedData,
            name: translate(
              getConfigItemValue('energyManagement.gasConsumption.translation'),
            ),
            type:
              selectedTimePeriod === translate('timePeriod.month')
              || selectedTimePeriod === translate('timePeriod.year')
                ? CHART_TYPES.BAR
                : CHART_TYPES.LINE,
          },
        ],
        loading: energyLoading || energyDataDailyLoading,
      },
      title: translate(
        getConfigItemValue('energyManagement.gasConsumption.translation'),
      ),
      titleDetails: (
        <TitleDetailsComponent
          iconName="sum"
          value={`${formatNumber(sum)} ${unit}`}
        />
      ),
    }
  }
  const renderPerformanceChartBoxProps = ({
    selectedTimePeriod,
    unit,
  }: {
    selectedTimePeriod: string
    unit: string
  }) => {
    const preparedPrevDaysPowerData = prepareData(
      selectedTime.split('-').length === 3
        ? energyData?.power?.[0]?.values || []
        : energyData?.power_prev_daily,
    )
    const preparedTodayPowerData = prepareData(energyData?.power_today?.[0]?.values || [])
    if (selectedTimePeriod === translate('timePeriod.month')) {
      preparedPrevDaysPowerData.push({
        value: preparedTodayPowerData[preparedTodayPowerData.length - 1]?.value,
        xAxisData: `${`0${now.getDate()}`.slice(-2)}.${`0${
          now.getMonth() + 1
        }`.slice(-2)}`,
      })
    }
    const preparedData = fillChartFutureValuesWithZeros({
      preparedData: preparedPrevDaysPowerData,
      selectedTime,
    })
    const sum = energyDataDaily?.power?.[0]?.value || 0
    return {
      series: {
        data: [
          {
            color: getConfigItemValue('energyManagement.performance.color'),
            data: preparedData,
            name: translate(
              getConfigItemValue('energyManagement.performance.translation'),
            ),
            type:
              selectedTimePeriod === translate('timePeriod.month')
              || selectedTimePeriod === translate('timePeriod.year')
                ? CHART_TYPES.BAR
                : CHART_TYPES.LINE,
          },
        ],
        loading: energyLoading || energyDataDailyLoading,
      },
      title: translate(
        getConfigItemValue('energyManagement.performance.translation'),
      ),
      titleDetails: (
        <TitleDetailsComponent
          iconName="sum"
          value={`${formatNumber(sum)} ${unit}`}
        />
      ),
    }
  }

  return {
    renderAirPressureChartBoxProps,
    renderGasConsumptionChartBoxProps,
    renderPerformanceChartBoxProps,
  }
}
