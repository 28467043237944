import { gql, useQuery } from '@apollo/client'
import Dropdown from 'components/Dropdown/Dropdown'
import IconComponent from 'components/Icon/Icon'
import { DEVICES_LIST, SELECTED_DEVICE_ID } from 'constants/storage'
import DeviceContext from 'contexts/device'
import { IDeviceProps } from 'hooks/useLayout'
import usePlsTranslation from 'hooks/usePlsTranslation'
import moment from 'moment'
import React, { useContext, useEffect, useState, useMemo } from 'react'
import { readItemFromStorage, saveItemInStorage } from 'utils/storage'
import { getConfigItemValue } from 'utils/config'
import QUERY_FOR_RUNTIME from '../../graphql/runtime'
import styles from './DeviceDetails.module.scss'

interface IDeviceDetailsProps {
  devicesList: IDeviceProps[]
}

const DeviceDetails = ({ devicesList }: IDeviceDetailsProps) => {
  const { translate } = usePlsTranslation()
  const { deviceId, setDeviceId } = useContext(DeviceContext)
  const pollInterval = getConfigItemValue('dashboard.pollInterval')

  useEffect(() => {
    const selectedDeviceIdFromStorage = readItemFromStorage({
      name: SELECTED_DEVICE_ID,
    })
    const devicesListFromStorage = JSON.parse(
      localStorage.getItem(DEVICES_LIST) || '{}',
    )
    if (setDeviceId && selectedDeviceIdFromStorage && devicesListFromStorage) {
      const foundDevice = devicesListFromStorage.find(
        (device: any) => +device.id === +selectedDeviceIdFromStorage,
      )
      if (foundDevice) {
        setDeviceId(+selectedDeviceIdFromStorage)
        saveItemInStorage({
          name: SELECTED_DEVICE_ID,
          value: selectedDeviceIdFromStorage,
        })
        return
      }
      setDeviceId(+devicesListFromStorage[0].id)
      saveItemInStorage({
        name: SELECTED_DEVICE_ID,
        value: devicesListFromStorage[0].id,
      })
    }
  }, [readItemFromStorage, saveItemInStorage, setDeviceId])

  const handleDeviceSelection = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedDropdownValue = event?.target?.value || ''
    if (setDeviceId) {
      setDeviceId(+selectedDropdownValue)
    }
    saveItemInStorage({
      name: SELECTED_DEVICE_ID,
      value: selectedDropdownValue,
    })
  }

  const prepareVariables = () =>
    useMemo(() => {
      const from = moment().subtract('1', 'days').format()
      const to = moment().add('1', 'day').format() // do this to be able to do polling with same variables
      return { from, to, deviceId: String(deviceId) }
    }, [deviceId])

  const [status, setStatus] = useState<any>()
  const { data } = useQuery(
    gql`
      ${QUERY_FOR_RUNTIME}
    `,
    {
      variables: prepareVariables(),
      fetchPolicy: 'no-cache',
      ...(pollInterval && { pollInterval }),
      notifyOnNetworkStatusChange: true,
    },
  )

  const getFormattedTime = () => moment().format('DD.MM.YY, HH:mm:ss')

  useEffect(() => {
    if (data) {
      let currentStatusData: any = []
      const running =
        data.bulkData[0]?.values[data.bulkData[0]?.values.length - 1]
      const starting =
        data.bulkData[1]?.values[data.bulkData[1]?.values.length - 1]
      const stopped =
        data.bulkData[2]?.values[data.bulkData[2]?.values.length - 1]
      const stopping =
        data.bulkData[3]?.values[data.bulkData[3]?.values.length - 1]
      currentStatusData.push({
        status: 'running',
        text: translate('systemStatus.running'),
        time: getFormattedTime(),
        ...running,
      })
      currentStatusData.push({
        status: 'starting',
        text: translate('systemStatus.starting'),
        time: getFormattedTime(),
        ...starting,
      })
      currentStatusData.push({
        status: 'stopped',
        text: translate('systemStatus.stopped'),
        time: getFormattedTime(),
        ...stopped,
      })
      currentStatusData.push({
        status: 'stopping',
        text: translate('systemStatus.stopping'),
        time: getFormattedTime(),
        ...stopping,
      })
      currentStatusData = currentStatusData.filter((item: any) => item.value)
      currentStatusData.sort(
        (a: any, b: any) => +new Date(b.timestamp) - +new Date(a.timestamp),
      )
      if (currentStatusData.length > 0) {
        setStatus(currentStatusData[0])
      } else {
        setStatus({
          text: translate('systemStatus.unknown'),
          time: getFormattedTime(),
          status: 'unknown',
        })
      }
    } else {
      setStatus({
        text: translate('systemStatus.unknown'),
        time: getFormattedTime(),
        status: 'unknown',
      })
    }
  }, [data])

  let styleClass = styles.deviceDetails__status__unknown
  if (status) {
    switch (status.status) {
      case 'running':
        styleClass = styles.deviceDetails__status__running
        break
      case 'starting':
        styleClass = styles.deviceDetails__status__starting
        break
      case 'stopped':
        styleClass = styles.deviceDetails__status__stopped
        break
      case 'stopping':
        styleClass = styles.deviceDetails__status__stopping
        break
      default:
        styleClass = styles.deviceDetails__status__unknown
        break
    }
  }

  return (
    <div className={styles.deviceDetails__container}>
      <Dropdown
        onChange={handleDeviceSelection}
        options={devicesList}
        selectedValue={deviceId}
      />
      <div className={styles.deviceDetails__statusContainer}>
        <span className={styles.deviceDetails__dateTime}>
          {status && status.time}
        </span>
        <span className={styles.deviceDetails__separator} />
        <span className={`${styleClass} ${styles.deviceDetails__status}`}>
          <IconComponent iconName="circle" />
          <span className={styles.deviceDetails__statusText}>
            {status && status.text}
          </span>
        </span>
      </div>
    </div>
  )
}

export default DeviceDetails
