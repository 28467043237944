const QUERY_FOR_RUNTIME = `
  query runtime ($deviceId: String!, $from: DateTime!, $to: DateTime!) {
    bulkData (
      deviceId: $deviceId
      data: [
        {
          from: $from # now - 24h
          to: $to # now
          category: PLANT_OVERVIEW
          subCategory: PLANT_STATUS
          variable: RUNNING
          index: 1
          variableIndex: 1
          limit: "1"
          order: Descending
        },
        {
          from: $from # now - 24h
          to: $to # now
          category: PLANT_OVERVIEW
          subCategory: PLANT_STATUS
          variable: STOPPED
          index: 1
          variableIndex: 1
          limit: "1"
          order: Descending
        },
        {
          from: $from # now - 24h
          to: $to # now
          category: PLANT_OVERVIEW
          subCategory: PLANT_STATUS
          variable: IS_STOPPING
          index: 1
          variableIndex: 1
          limit: "1"
          order: Descending
        },
        {
          from: $from # now - 24h
          to: $to # now
          category: PLANT_OVERVIEW
          subCategory: PLANT_STATUS
          variable: IS_STOPPING
          index: 1
          variableIndex: 1
          limit: "1"
          order: Descending
        },
        {
          from: $from # now - 24h
          to: $to # now
          category: PLANT_OVERVIEW
          subCategory: PLANT_STATUS
          variable: IS_STARTING
          index: 1
          variableIndex: 1
          limit: "1"
          order: Descending
        }
      ]
    ) {
      values {
        value
        timestamp
      }
    }
  }
`

export default QUERY_FOR_RUNTIME
