export const BOX_NAME = {
  AIR_PRESSURE: 'AIR_PRESSURE',
  AIR_PRESSURE_BAR_CHART: 'AIR_PRESSURE_BAR_CHART',
  BURNER_PERFORMANCE: 'BURNER_PERFORMANCE',
  DRYING: 'DRYING',
  GAS_CONSUMPTION_LINE_CHART: 'GAS_CONSUMPTION_LINE_CHART',
  GAS_CONSUMPTION_BAR_CHART: 'GAS_CONSUMPTION_BAR_CHART',
  INPUT: 'INPUT',
  OUTPUT: 'OUTPUT',
  OVERVIEW: 'OVERVIEW',
  PERFORMANCE_LINE_CHART: 'PERFORMANCE_LINE_CHART',
  PERFORMANCE_BAR_CHART: 'PERFORMANCE_BAR_CHART',
  PROCESSING_RATE: 'PROCESSING_RATE',
  PROCESSING_RATE_DAILY: 'PROCESSING_RATE_DAILY',
  PRODUCTION_QUANTITIES_DRYING: 'PRODUCTION_QUANTITIES_DRYING',
  PRODUCTION_QUANTITIES_INPUT: 'PRODUCTION_QUANTITIES_INPUT',
  PRODUCTION_QUANTITIES_OUTPUT: 'PRODUCTION_QUANTITIES_OUTPUT',
  QUANTITY_INDICATIONS: 'QUANTITY_INDICATIONS',
  QUANTITY_INDICATIONS_DASHBOARD: 'QUANTITY_INDICATIONS_DASHBOARD',
  QUANTITY_INDICATIONS_DETAILED: 'QUANTITY_INDICATIONS_DETAILED',
  SCORES_N: 'SCORES_N',
  SORTING_MACHINES: 'SORTING_MACHINES',
  TEMPERATURES: 'TEMPERATURES',
}
export const BOX_WIDTH = {
  FULL: '100%',
  HALF: '49%',
  NARROW: '34%',
  WIDE: '64%',
}
