import Box from 'components/Box/Box'
import { BOX_NAME, BOX_WIDTH } from 'constants/box'
import React from 'react'
import { getConfigItemValue } from 'utils/config'
import styles from './index.module.scss'

interface ISortingMachinesProps {
  selectedMachineId?: number
  selectedMachineName?: string
  selectedTime?: string
  selectedTimePeriod?: string
}

const SortingMachinesPage = ({
  selectedMachineId,
  selectedMachineName,
  selectedTime,
  selectedTimePeriod,
}: ISortingMachinesProps) => (
  <div className={styles.sortingMachinesPage__container}>
    {getConfigItemValue('sortingMachines.elements')
      .filter((machine: any) => machine.title === selectedMachineName)
      .map((element: any) => (element.scores || []).map((el: any, index: any) => (
        <Box
          key={`${element.title}_${el.translation}`}
          machineCount={element.scores.length}
          name={BOX_NAME.SCORES_N}
          number={index + 1}
          selectedMachineId={selectedMachineId}
          selectedTime={selectedTime}
          selectedTimePeriod={selectedTimePeriod}
          width={BOX_WIDTH.HALF}
          yAxisRange={{
            max: el.chartMax,
            min: el.chartMin,
          }}
        />
      )))}
  </div>
)

SortingMachinesPage.defaultProps = {
  selectedMachineId: 0,
  selectedMachineName: '',
  selectedTime: '',
  selectedTimePeriod: '',
}

export default SortingMachinesPage
