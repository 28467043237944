import IconComponent from 'components/Icon/Icon'
import MENU from 'constants/menu'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import INotificationsProps from './Notifications.interface'
import styles from './Notifications.module.scss'

const Notifications = ({ newNotifications }: INotificationsProps) => {
  const history = useNavigate()
  const location = useLocation()

  const handleNotificationIconClick = () => {
    if (location.pathname !== MENU.MESSAGE_LIST.HREF) {
      history(MENU.MESSAGE_LIST.HREF)
    }
  }

  const newErrors = newNotifications.errors
  const newWarnings = newNotifications.warnings
  const displayIcon = newErrors || newWarnings

  return (
    <div
      className={styles.notifications__container}
      onClick={() => handleNotificationIconClick()}
      onKeyDown={() => handleNotificationIconClick()}
      role="presentation"
    >
      <IconComponent
        className={`${styles.notifications__icon} ${styles['notifications__icon--notificationsIcon']}`}
        iconName="bell"
      />
      {displayIcon && (
        <IconComponent
          className={`${styles.notifications__icon} ${
            newErrors
              ? `${styles['notifications__icon--newNotificationsIcon']} ${styles['notifications__icon--newNotificationsIcon--forErrors']}`
              : ''
          } ${
            !newErrors && newWarnings
              ? `${styles['notifications__icon--newNotificationsIcon']} ${styles['notifications__icon--newNotificationsIcon--forWarnings']}`
              : ''
          }`}
          iconName="exclamationMarkInCircle"
        />
      )}
    </div>
  )
}

export default Notifications
