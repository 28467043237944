import DeviceDetails from 'components/DeviceDetails/DeviceDetails'
import Logo from 'components/Logo/Logo'
import Notifications from 'components/Notifications/Notifications'
import Separator from 'components/Separator/Separator'
import DeviceContext from 'contexts/device'
import { IDeviceProps } from 'hooks/useLayout'
import useMessageList from 'hooks/useMessageList'
import React, { useContext } from 'react'
import styles from './Header.module.scss'

interface IHeaderProps {
  devicesList: IDeviceProps[]
}

const Header = ({ devicesList }: IHeaderProps) => {
  const { deviceId } = useContext(DeviceContext)
  const { messageList } = useMessageList({ deviceId })

  return (
    <div className={styles.header__containerExternal}>
      <div className={styles.header__container}>
        <div className={styles.header__containerLeft}>
          <Logo className={styles.header__logo} />
          <Separator />
          <DeviceDetails devicesList={devicesList} />
        </div>
        <div className={styles.header__containerRight}>
          <Notifications
            newNotifications={{
              errors: messageList.some((message) => message.status === 'error'),
              warnings: messageList.some(
                (message) => message.status === 'warning',
              ),
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Header
