import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translations from 'translations/translations.json'

const resources = {
  de: {
    translation: translations,
  },
}

i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  lng: 'de',
  resources,
})

export default i18n
