import IconComponent from 'components/Icon/Icon'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './Menu.module.scss'

interface IMenuProps {
  hideMenu: () => void
  menuItems: any
}

const Menu = ({ hideMenu, menuItems }: IMenuProps) => (
  <ul className={styles.menu__container}>
    {menuItems.map((menuItem: any) => (
      <NavLink
        className={({ isActive }: { isActive: boolean }) => (isActive
          ? `${styles.menu__item} ${styles['menu__item--active']}`
          : styles.menu__item)}
        key={menuItem.text}
        onClick={hideMenu}
        to={menuItem.href}
      >
        <li className={styles.menu__itemContainer} key={menuItem.text}>
          <span className={styles.menu__iconContainer}>
            <IconComponent iconName={menuItem.icon} />
          </span>
          <span className={styles.menu__textContainer}>{menuItem.text}</span>
        </li>
      </NavLink>
    ))}
  </ul>
)

export default Menu
