import { gql, useQuery } from '@apollo/client'
import CHART_COLORS from 'constants/chartColors'
import CHART_TYPES from 'constants/chartTypes'
import { QUERY_FOR_SORTING } from 'graphql/queries'
import usePlsTranslation from 'hooks/usePlsTranslation'
import { useMemo } from 'react'
import moment from 'moment'
import {
  fillChartFutureValuesWithZeros,
  formatXAxis,
  renderColumnsQuantity,
  renderTimeRange,
  getQueryRefetchParams,
} from 'utils/charts'

interface IUseChartBoxProps {
  deviceId: number
  machineCount: number
  selectedMachineId: number
  selectedTime: string
}

export default ({
  deviceId,
  machineCount,
  selectedMachineId,
  selectedTime,
}: IUseChartBoxProps) => {
  const { translate } = usePlsTranslation()
  const { from, to } = renderTimeRange(selectedTime)
  const prepareQuery = (queryString: string) => useMemo(() => {
    const segments = selectedTime.split('-')
    const aggregation = segments.length === 3 ? 'TimeWeightedAverage' : 'Max'
    const preparedQueryString = queryString
      .replaceAll('{{{aggregation}}}', aggregation)
      .replaceAll(
        '{{{calculationTimeRange}}}',
        String(renderColumnsQuantity(selectedTime)),
      )
      .replaceAll('{{{deviceId}}}', String(deviceId))
      .replaceAll('{{{from}}}', moment(from).toISOString())
      .replaceAll('{{{machineId}}}', String(selectedMachineId))
      .replaceAll('{{{to}}}', moment(to).toISOString())
    return gql`
        ${preparedQueryString}
      `
  }, [deviceId, from, queryString, to])
  const { data: scoresData, loading: scoresLoading } = useQuery(
    prepareQuery(QUERY_FOR_SORTING(machineCount)),
    getQueryRefetchParams(selectedTime, 0)
  )
  const prepareData = (
    arrayWithData: { timestamp: string; value: number }[],
  ) => {
    const preparedData = arrayWithData.map((details) => {
      return {
        value: Math.round(details.value * 100) / 100,
        xAxisData: formatXAxis({ selectedTime, timestamp: details.timestamp }),
      }
    })
    return fillChartFutureValuesWithZeros({ preparedData, selectedTime })
  }
  const renderScoresChartBoxProps = ({
    chartNumber,
  }: {
    chartNumber: number
  }) => {
    const fontSize = 14
    const preparedScoresData = prepareData(
      scoresData?.[`sort_rate_${chartNumber}`]?.[0]?.values || [],
    )
    const title = `${translate('chart.scores')} - VR${chartNumber}`
    const alarmValue = scoresData?.[`sort_rate_${chartNumber}_alarm`]?.at(-1).value || 0
    const alarmColor = 'red'
    const warningValue = scoresData?.[`sort_rate_${chartNumber}_warning`]?.at(-1)?.value || 0
    const warningColor = 'orange'
    return {
      series: {
        data: [
          {
            color: CHART_COLORS.COLOR_1,
            data: preparedScoresData,
            markLine: {
              data: [
                {
                  label: {
                    color: alarmColor,
                    fontSize,
                    // formatter: `{b}: ${alarmValue}`,
                    formatter: '',
                    position: 'insideEndTop',
                    textBorderWidth: 2,
                  },
                  lineStyle: {
                    normal: {
                      color: alarmColor,
                      type: 'solid',
                    },
                  },
                  name: translate('alarmLimit'),
                  symbol: 'none',
                  yAxis: alarmValue,
                },
                {
                  label: {
                    color: warningColor,
                    fontSize,
                    // formatter: `{b}: ${warningValue}`,
                    formatter: '',
                    position: 'insideEndTop',
                    textBorderWidth: 2,
                  },
                  lineStyle: {
                    normal: {
                      color: warningColor,
                      type: 'solid',
                    },
                  },
                  name: translate('warningLimit'),
                  symbol: 'none',
                  yAxis: warningValue,
                },
              ],
            },
            name: title,
            type: CHART_TYPES.LINE,
          },
        ],
        loading: scoresLoading,
      },
      title,
      titleDetails: null
      // titleDetails: (
      //   <TitleDetailsComponent iconName="sum" value={formatNumber(sum || 0)} />
      // ),
    }
  }

  return {
    renderScoresChartBoxProps,
  }
}
