import ChartComponent from 'components/Chart/Chart'
import LoaderComponent from 'components/Loader/Loader'
import { BOX_NAME } from 'constants/box'
import usePlsTranslation from 'hooks/usePlsTranslation'
import React from 'react'
import { getConfigItemValue } from 'utils/config'
import useBurnerPerformanceChartBox from './useBurnerPerformanceChartBox'
import useCurrentDate from './useCurrentDate'
import usePerformanceLineChartBox from './usePerformanceLineChartBox'
import usePerformanceBarChartBox from './usePerformanceBarChartBox'
import useQuantityBarChartBox from './useQuantityBarChartBox'
import useQuantityLineChartBox from './useQuantityLineChartBox'
import useScoresChartBox from './useScoresChartBox'
import useTemperaturesChartBox from './useTemperaturesChartBox'

interface IUseBoxInterface {
  boxName: string
  displayTitleDetails?: boolean
  machineCount: number
  number: number
  selectedDeviceId: number
  selectedMachineId: number
  selectedTime: string
  selectedTimePeriod?: string
  yAxisRange?: {
    max: number
    min: number
  }
  index: number // this is the index for e.g. output 1
}

export default ({
  boxName,
  displayTitleDetails,
  machineCount,
  number,
  selectedDeviceId,
  selectedMachineId,
  selectedTime,
  selectedTimePeriod,
  yAxisRange,
  index,
}: IUseBoxInterface) => {
  const { translate } = usePlsTranslation()

  const selectedTimePeriodValue = selectedTimePeriod || ''

  const renderTimePeriodKey = (timePeriodTranslatedName: string = '') => {
    switch (timePeriodTranslatedName) {
      case translate('timePeriod.day'): {
        return 'day'
      }
      case translate('timePeriod.month'): {
        return 'month'
      }
      case translate('timePeriod.year'): {
        return 'year'
      }
      default: {
        return ''
      }
    }
  }

  const selectedTimePeriodKey = renderTimePeriodKey(selectedTimePeriodValue)

  const renderBoxBody = () => {
    switch (boxName) {
      case BOX_NAME.AIR_PRESSURE: {
        const { renderAirPressureChartBoxProps } = usePerformanceLineChartBox({
          deviceId: selectedDeviceId,
          selectedTime: selectedTime || '',
        })
        const { series, title, titleDetails } = renderAirPressureChartBoxProps({
          selectedTimePeriod: selectedTimePeriodValue,
          unit: getConfigItemValue('energyManagement.airPressure.unitSummary'),
        })
        return {
          content: (
            <ChartComponent
              series={series}
              unit={getConfigItemValue('energyManagement.airPressure.unit')}
              yAxisValueMax={getConfigItemValue(
                `energyManagement.airPressure.chartMax.${selectedTimePeriodKey}`,
              )}
              yAxisValueMin={getConfigItemValue(
                `energyManagement.airPressure.chartMin.${selectedTimePeriodKey}`,
              )}
            />
          ),
          title,
          titleDetails: series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            titleDetails
          ),
        }
      }
      case BOX_NAME.BURNER_PERFORMANCE: {
        const { renderBurnerPerformanceChartBoxProps } =
          useBurnerPerformanceChartBox({
            deviceId: selectedDeviceId,
            selectedTime: selectedTime || '',
          })
        const { series, title, titleDetails } =
          renderBurnerPerformanceChartBoxProps({
            selectedTimePeriod: selectedTimePeriodValue,
            unit: getConfigItemValue('drying.burnerPerformance.unitSummary'),
          })
        return {
          content: (
            <ChartComponent
              series={series}
              unit={getConfigItemValue('drying.burnerPerformance.unit')}
              yAxisValueMax={getConfigItemValue(
                'drying.burnerPerformance.chartMax',
              )}
              yAxisValueMin={getConfigItemValue(
                'drying.burnerPerformance.chartMin',
              )}
            />
          ),
          title,
          titleDetails: series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            titleDetails
          ),
        }
      }
      case BOX_NAME.GAS_CONSUMPTION_LINE_CHART: {
        const { renderGasConsumptionChartBoxProps } =
          usePerformanceLineChartBox({
            deviceId: selectedDeviceId,
            selectedTime: selectedTime || '',
          })
        const { series, title, titleDetails } =
          renderGasConsumptionChartBoxProps({
            selectedTimePeriod: selectedTimePeriodValue,
            unit: getConfigItemValue(
              'energyManagement.gasConsumption.unitSummary',
            ),
          })
        return {
          content: (
            <ChartComponent
              series={series}
              unit={getConfigItemValue('energyManagement.gasConsumption.unit')}
              yAxisValueMax={getConfigItemValue(
                `energyManagement.gasConsumption.chartMax.${selectedTimePeriodKey}`,
              )}
              yAxisValueMin={getConfigItemValue(
                `energyManagement.gasConsumption.chartMin.${selectedTimePeriodKey}`,
              )}
            />
          ),
          title,
          titleDetails: series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            titleDetails
          ),
        }
      }
      case BOX_NAME.PERFORMANCE_LINE_CHART: {
        const { renderPerformanceChartBoxProps } = usePerformanceLineChartBox({
          deviceId: selectedDeviceId,
          selectedTime: selectedTime || '',
        })
        const { series, title, titleDetails } = renderPerformanceChartBoxProps({
          selectedTimePeriod: selectedTimePeriodValue,
          unit: getConfigItemValue('energyManagement.performance.unitSummary'),
        })
        return {
          content: (
            <ChartComponent
              series={series}
              unit={getConfigItemValue('energyManagement.performance.unit')}
              yAxisValueMax={getConfigItemValue(
                `energyManagement.performance.chartMax.${selectedTimePeriodKey}`,
              )}
              yAxisValueMin={getConfigItemValue(
                `energyManagement.performance.chartMin.${selectedTimePeriodKey}`,
              )}
            />
          ),
          title,
          titleDetails: series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            titleDetails
          ),
        }
      }
      case BOX_NAME.GAS_CONSUMPTION_BAR_CHART: {
        const { renderGasConsumptionChartBoxProps } = usePerformanceBarChartBox(
          {
            deviceId: selectedDeviceId,
            selectedTime: selectedTime || '',
          },
        )
        const { series, title, titleDetails } =
          renderGasConsumptionChartBoxProps({
            selectedTimePeriod: selectedTimePeriodValue,
            unit: getConfigItemValue(
              'energyManagement.gasConsumption.unitSummary',
            ),
          })
        return {
          content: (
            <ChartComponent
              series={series}
              unit={getConfigItemValue('energyManagement.gasConsumption.unitSummary')}
              yAxisValueMax={getConfigItemValue(
                `energyManagement.gasConsumption.chartMax.${selectedTimePeriodKey}`,
              )}
              yAxisValueMin={getConfigItemValue(
                `energyManagement.gasConsumption.chartMin.${selectedTimePeriodKey}`,
              )}
            />
          ),
          title,
          titleDetails: series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            titleDetails
          ),
        }
      }
      case BOX_NAME.AIR_PRESSURE_BAR_CHART: {
        const { renderAirPressureChartBoxProps } = usePerformanceBarChartBox(
          {
            deviceId: selectedDeviceId,
            selectedTime: selectedTime || '',
          },
        )
        const { series, title, titleDetails } =
        renderAirPressureChartBoxProps({
            selectedTimePeriod: selectedTimePeriodValue,
            unit: getConfigItemValue(
              'energyManagement.airPressure.unitSummary',
            ),
          })
        return {
          content: (
            <ChartComponent
              series={series}
              unit={getConfigItemValue('energyManagement.airPressure.unitSummary')}
              yAxisValueMax={getConfigItemValue(
                `energyManagement.airPressure.chartMax.${selectedTimePeriodKey}`,
              )}
              yAxisValueMin={getConfigItemValue(
                `energyManagement.airPressure.chartMin.${selectedTimePeriodKey}`,
              )}
            />
          ),
          title,
          titleDetails: series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            titleDetails
          ),
        }
      }
      case BOX_NAME.PERFORMANCE_BAR_CHART: {
        const { renderPerformanceChartBoxProps } = usePerformanceBarChartBox({
          deviceId: selectedDeviceId,
          selectedTime: selectedTime || '',
        })
        const { series, title, titleDetails } = renderPerformanceChartBoxProps({
          selectedTimePeriod: selectedTimePeriodValue,
          unit: getConfigItemValue('energyManagement.performance.unitSummary'),
        })
        return {
          content: (
            <ChartComponent
              series={series}
              unit={getConfigItemValue('energyManagement.performance.unitSummary')}
              yAxisValueMax={getConfigItemValue(
                `energyManagement.performance.chartMax.${selectedTimePeriodKey}`,
              )}
              yAxisValueMin={getConfigItemValue(
                `energyManagement.performance.chartMin.${selectedTimePeriodKey}`,
              )}
            />
          ),
          title,
          titleDetails: series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            titleDetails
          ),
        }
      }
      case BOX_NAME.PROCESSING_RATE: {
        const { renderProcessingRateChartBoxProps } = useQuantityBarChartBox({
          deviceId: selectedDeviceId,
          selectedTime: selectedTime || '',
          selectedTimePeriod: selectedTimePeriodValue,
        })
        const { series, title, titleDetails } =
          renderProcessingRateChartBoxProps({
            unit: getConfigItemValue(
              'productionQuantities.processingRate.unitSummary',
            ),
          })
        return {
          content: (
            <ChartComponent
              series={series}
              unit={getConfigItemValue(
                'productionQuantities.processingRate.unit',
              )}
              yAxisValueMax={getConfigItemValue(
                `productionQuantities.processingRate.chartMax.${selectedTimePeriodKey}`,
              )}
              yAxisValueMin={getConfigItemValue(
                `productionQuantities.processingRate.chartMin.${selectedTimePeriodKey}`,
              )}
            />
          ),
          title,
          titleDetails: series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            titleDetails
          ),
        }
      }
      case BOX_NAME.PROCESSING_RATE_DAILY: {
        const { renderProcessingRateChartBoxPropsDaily } =
          useQuantityLineChartBox({
            deviceId: selectedDeviceId,
            selectedTime: selectedTime || '',
          })
        const { series, title, titleDetails } =
          renderProcessingRateChartBoxPropsDaily({
            unit: getConfigItemValue(
              'productionQuantities.processingRate.unitSummary',
            ),
          })
        return {
          content: (
            <ChartComponent
              series={series}
              unit={getConfigItemValue(
                'productionQuantities.processingRate.unit',
              )}
              yAxisValueMax={getConfigItemValue(
                'productionQuantities.processingRate.chartMax.day',
              )}
              yAxisValueMin={getConfigItemValue(
                'productionQuantities.processingRate.chartMin.day',
              )}
            />
          ),
          title,
          titleDetails: series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            titleDetails
          ),
        }
      }
      case BOX_NAME.PRODUCTION_QUANTITIES_DRYING: {
        const { renderProductionQuantitiesDryingChartBoxProps } =
          useQuantityBarChartBox({
            deviceId: selectedDeviceId,
            selectedTime: selectedTime || '',
            selectedTimePeriod: selectedTimePeriodValue,
          })
        const unit = getConfigItemValue(
          'productionQuantities.quantityIndications.dryingUnit',
        )
        const { series, title, titleDetails } =
          renderProductionQuantitiesDryingChartBoxProps({
            unit,
          })
        return {
          content: (
            <ChartComponent
              series={series}
              unit={unit}
              yAxisValueMax={getConfigItemValue(
                `productionQuantities.quantityIndications.dryingChartMax.${selectedTimePeriodKey}`,
              )}
              yAxisValueMin={getConfigItemValue(
                `productionQuantities.quantityIndications.dryingChartMin.${selectedTimePeriodKey}`,
              )}
            />
          ),
          title,
          titleDetails: series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            titleDetails
          ),
        }
      }
      case BOX_NAME.PRODUCTION_QUANTITIES_INPUT: {
        const { renderProductionQuantitiesInputChartBoxProps } =
          useQuantityBarChartBox({
            deviceId: selectedDeviceId,
            selectedTime: selectedTime || '',
            selectedTimePeriod: selectedTimePeriodValue,
          })
        const unit = getConfigItemValue(
          'productionQuantities.quantityIndications.inputUnit',
        )
        const { series, title, titleDetails } =
          renderProductionQuantitiesInputChartBoxProps({
            unit,
          })
        return {
          content: (
            <ChartComponent
              series={series}
              unit={unit}
              yAxisValueMax={getConfigItemValue(
                `productionQuantities.quantityIndications.inputChartMax.${selectedTimePeriodKey}`,
              )}
              yAxisValueMin={getConfigItemValue(
                `productionQuantities.quantityIndications.inputChartMin.${selectedTimePeriodKey}`,
              )}
            />
          ),
          title,
          titleDetails: series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            titleDetails
          ),
        }
      }
      case BOX_NAME.PRODUCTION_QUANTITIES_OUTPUT: {
        const { renderProductionQuantitiesOutputChartsBoxProps } =
          useQuantityBarChartBox({
            deviceId: selectedDeviceId,
            selectedTime: selectedTime || '',
            selectedTimePeriod: selectedTimePeriodValue,
          })
        const unit = getConfigItemValue(
          'productionQuantities.quantityIndications.outputUnit',
        )
        const outputChart = renderProductionQuantitiesOutputChartsBoxProps({
          unit,
        })[index]

        return {
          content: (
            <ChartComponent
              series={outputChart.series}
              unit={unit}
              yAxisValueMax={getConfigItemValue(
                `productionQuantities.quantityIndications.outputChartMax.${selectedTimePeriodKey}`,
              )}
              yAxisValueMin={getConfigItemValue(
                `productionQuantities.quantityIndications.outputChartMin.${selectedTimePeriodKey}`,
              )}
            />
          ),
          title: outputChart.title,
          titleDetails: outputChart.series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            outputChart.titleDetails
          ),
        }
      }
      case BOX_NAME.QUANTITY_INDICATIONS: {
        const { renderQuantityIndicationChartBoxProps } =
          useQuantityLineChartBox({
            deviceId: selectedDeviceId,
            selectedTime: selectedTime || '',
          })
        const { series, statistics, title, titleDetails } =
          renderQuantityIndicationChartBoxProps()
        return {
          content: (
            <ChartComponent
              series={series}
              unit={getConfigItemValue(
                'productionQuantities.quantityIndications.unit',
              )}
              yAxisValueMax={getConfigItemValue(
                'productionQuantities.quantityIndications.chartMax',
              )}
              yAxisValueMin={getConfigItemValue(
                'productionQuantities.quantityIndications.chartMin',
              )}
            />
          ),
          statistics,
          title,
          // eslint-disable-next-line no-nested-ternary
          titleDetails: !displayTitleDetails ? undefined : series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            titleDetails
          ),
        }
      }
      case BOX_NAME.QUANTITY_INDICATIONS_DASHBOARD: {
        const { year, month, day } = useCurrentDate()
        const { renderQuantityIndicationDashboardChartBoxProps } =
          useQuantityLineChartBox({
            deviceId: selectedDeviceId,
            selectedTime: selectedTime || `${year}-${month}-${day}`,
            pollInterval: getConfigItemValue('dashboard.pollInterval'),
          })
        const { series, statistics, title, titleDetails } =
          renderQuantityIndicationDashboardChartBoxProps()
        return {
          content: (
            <ChartComponent
              series={series}
              unit={getConfigItemValue('dashboard.quantityIndications.unit')}
              yAxisValueMax={getConfigItemValue(
                'dashboard.quantityIndications.chartMax',
              )}
              yAxisValueMin={getConfigItemValue(
                'dashboard.quantityIndications.chartMin',
              )}
            />
          ),
          statistics,
          title,
          // eslint-disable-next-line no-nested-ternary
          titleDetails: !displayTitleDetails ? undefined : series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            titleDetails
          ),
        }
      }
      case BOX_NAME.SCORES_N: {
        const { renderScoresChartBoxProps } = useScoresChartBox({
          deviceId: selectedDeviceId,
          machineCount,
          selectedMachineId: selectedMachineId || 0,
          selectedTime: selectedTime || '',
        })
        const unit = ''
        const { series, title, titleDetails } = renderScoresChartBoxProps({
          chartNumber: number,
        })
        return {
          content: (
            <ChartComponent
              series={series}
              unit={unit}
              yAxisValueMax={yAxisRange?.max}
              yAxisValueMin={yAxisRange?.min}
            />
          ),
          title,
          titleDetails: series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            titleDetails
          ),
        }
      }
      case BOX_NAME.TEMPERATURES: {
        const { renderTemperaturesChartBoxProps } = useTemperaturesChartBox({
          deviceId: selectedDeviceId,
          selectedTime: selectedTime || '',
        })
        const unit = getConfigItemValue('drying.temperatures.unit')
        const { series, title } = renderTemperaturesChartBoxProps()
        return {
          content: (
            <ChartComponent
              series={series}
              unit={unit}
              yAxisValueMax={getConfigItemValue('drying.temperatures.chartMax')}
              yAxisValueMin={getConfigItemValue('drying.temperatures.chartMin')}
            />
          ),
          title,
        }
      }
      default: {
        return {
          content: <></>,
          statistics: {
            data: [],
            loading: false,
          },
          title: '',
          titleDetails: <></>,
        }
      }
    }
  }

  return { renderBoxBody }
}
