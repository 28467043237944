import IconComponent from 'components/Icon/Icon'
import LoaderComponent from 'components/Loader/Loader'
import React from 'react'
import { IChartStatisticDetails } from './ChartStatistic.interface'
import styles from './ChartStatistic.module.scss'

const ChartStatisticComponent = ({
  details,
  loading,
}: IChartStatisticDetails) => {
  const renderStatisticContent = () => {
    if (loading) {
      return <LoaderComponent style={{ height: '19px' }} />
    }
    return <span className={styles.chartStatistic__value}>{details.value}</span>
  }

  return (
    <div className={styles.chartStatistic__container}>
      <div className={styles.chartStatistic__nameContainer}>
        <span className={styles.chartStatistic__name}>{details.name}</span>
      </div>
      <div className={styles.chartStatistic__valueContainer}>
        <IconComponent iconName="sum" />
        {renderStatisticContent()}
      </div>
    </div>
  )
}

export default ChartStatisticComponent
