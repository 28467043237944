import LoaderComponent from 'components/Loader/Loader'
import ReactECharts from 'echarts-for-react'
import useChart from 'hooks/useChart'
import React from 'react'
import IChartProps from './Chart.interface'
import styles from './Chart.module.scss'

const ChartComponent: React.FC<IChartProps> = ({
  height = '370px',
  hideXAxisLabel,
  legend = { show: true },
  series,
  tooltipData = [],
  tooltipRenderer = () => {},
  tooltipTrigger = 'axis',
  unit = 't/h',
  xAxisType = 'category',
  yAxisType = 'value',
  yAxisValueMax,
  yAxisValueMin,
}) => {
  const { option } = useChart({
    hideXAxisLabel,
    legend,
    series,
    tooltipData,
    tooltipRenderer,
    tooltipTrigger,
    unit,
    xAxisType,
    yAxisType,
    yAxisValueMax,
    yAxisValueMin,
  })

  if (series.loading) {
    return <LoaderComponent style={{ height }} />
  }

  return (
    <div className={styles.chart__container} style={{ height }}>
      <ReactECharts option={option} />
    </div>
  )
}

export default ChartComponent
