const storage = localStorage

export const readItemFromStorage = ({ name }: { name: string }) => storage.getItem(name)

export const saveItemInStorage = ({
  name,
  value,
}: {
  name: string
  value: string
}) => {
  storage.setItem(name, value)
}
