import { gql, useQuery } from '@apollo/client'
import QUERY_FOR_MESSAGES from 'graphql/message_list_queries'
import usePlsTranslation from 'hooks/usePlsTranslation'
import moment from 'moment'
import { useMemo } from 'react'
import { getConfigItemValue } from 'utils/config'

export interface IMessageProps {
  content: string
  date?: string
  id: number
  status?: string
  time?: string
  title: string
}

interface IUseMessageListProps {
  deviceId: number
}

export default ({ deviceId }: IUseMessageListProps) => {
  const { translate } = usePlsTranslation()
  const warningCount = getConfigItemValue('messageList.warningTypes').length
  const errorCount = getConfigItemValue('messageList.errorTypes').length

  const pollInterval = getConfigItemValue('dashboard.pollInterval')

  const prepareQueryVariables = () =>
    useMemo(() => {
      return {
        deviceId: String(deviceId),
        to: moment().add('1', 'day').format(), // do this to be able to do polling with same variables,
      }
    }, [deviceId])

  let data: any = []
  let loading = false

  const { data: dataFromQuery, loading: loadingFromQuery } = useQuery(
    gql`
      ${QUERY_FOR_MESSAGES(warningCount, errorCount)}
    `,
    {
      variables: prepareQueryVariables(),
      ...(pollInterval && { pollInterval }),
      notifyOnNetworkStatusChange: true,
    },
  )
  data = dataFromQuery
  loading = loadingFromQuery

  const messageList: IMessageProps[] = []

  if (data && data.bulkData?.length) {
    // Assuming `bulkData` structure is correctly mapped to errors and warnings
    data.bulkData.forEach((entry: any, index: number) => {
      const isWarning = index >= errorCount // Assuming warnings come after errors
      const typeConfig = isWarning
        ? getConfigItemValue('messageList.warningTypes')[index - errorCount]
        : getConfigItemValue('messageList.errorTypes')[index]
      entry.values.forEach((value: any) => {
        if (value.value === 1) {
          messageList.push({
            content: translate(typeConfig.translation),
            date: moment(value.timestamp).format('DD.MM.YYYY'),
            id: index + 1,
            status: isWarning ? 'warning' : 'error',
            time: moment(value.timestamp).format('HH:mm:ss'),
            title: translate(isWarning ? 'warning' : 'error'),
          })
        }
      })
    })
  }

  return {
    loading: loading && !dataFromQuery,
    messageList,
  }
}
