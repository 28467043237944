import { LAST_VALUE_START_DATE } from 'utils/common'

export const enum DataIndex {
  INPUT_MEASUREMENT,
  INPUT_ALARM_UPPER,
  INPUT_ALARM_UPPER_ACTIVE,
  INPUT_ALARM_LOWER,
  INPUT_ALARM_LOWER_ACTIVE,
  INPUT_WARNING_UPPER,
  INPUT_WARNING_UPPER_ACTIVE,
  INPUT_WARNING_LOWER,
  INPUT_WARNING_LOWER_ACTIVE,
  PROCESSING_RATE_MEASUREMENT,
  PROCESSING_RATE_ALARM_LOWER,
  PROCESSING_RATE_ALARM_LOWER_ACTIVE,
  PROCESSING_RATE_WARNING_LOWER,
  PROCESSING_RATE_WARNING_LOWER_ACTIVE,
  BURNER_PERFORMANCE_MEASUREMENT,
  BURNER_PERFORMANCE_ALARM_UPPER,
  BURNER_PERFORMANCE_ALARM_UPPER_ACTIVE,
  BURNER_PERFORMANCE_ALARM_LOWER,
  BURNER_PERFORMANCE_ALARM_LOWER_ACTIVE,
  BURNER_PERFORMANCE_WARNING_UPPER,
  BURNER_PERFORMANCE_WARNING_UPPER_ACTIVE,
  BURNER_PERFORMANCE_WARNING_LOWER,
  BURNER_PERFORMANCE_WARNING_LOWER_ACTIVE,
  PERFORMANCE_MEASUREMENT,
  PERFORMANCE_ALARM_UPPER,
  PERFORMANCE_ALARM_UPPER_ACTIVE,
  PERFORMANCE_WARNING_UPPER,
  PERFORMANCE_WARNING_UPPER_ACTIVE,
  COMPRESSED_AIR_QUANTITY_MEASUREMENT,
  COMPRESSED_AIR_QUANTITY_ALARM_UPPER,
  COMPRESSED_AIR_QUANTITY_ALARM_UPPER_ACTIVE,
  COMPRESSED_AIR_QUANTITY_ALARM_LOWER,
  COMPRESSED_AIR_QUANTITY_ALARM_LOWER_ACTIVE,
  COMPRESSED_AIR_QUANTITY_WARNING_UPPER,
  COMPRESSED_AIR_QUANTITY_WARNING_UPPER_ACTIVE,
  COMPRESSED_AIR_QUANTITY_WARNING_LOWER,
  COMPRESSED_AIR_QUANTITY_WARNING_LOWER_ACTIVE
}

interface QueryParam {
  category: string;
  subCategory: string;
  variable: string;
  index?: number;
}

const QUERY_PARAMS: QueryParam[] = [
  { category: "QUANTITIES", subCategory: "INPUT", variable: "MEASUREMENT" },
  { category: "QUANTITIES", subCategory: "INPUT", variable: "ALARM_LIMIT_UPPER" },
  { category: "QUANTITIES", subCategory: "INPUT", variable: "ALARM_LIMIT_UPPER_ACTIVE" },
  { category: "QUANTITIES", subCategory: "INPUT", variable: "ALARM_LIMIT_LOWER" },
  { category: "QUANTITIES", subCategory: "INPUT", variable: "ALARM_LIMIT_LOWER_ACTIVE" },
  { category: "QUANTITIES", subCategory: "INPUT", variable: "WARNING_LIMIT_UPPER" },
  { category: "QUANTITIES", subCategory: "INPUT", variable: "WARNING_LIMIT_UPPER_ACTIVE" },
  { category: "QUANTITIES", subCategory: "INPUT", variable: "WARNING_LIMIT_LOWER" },
  { category: "QUANTITIES", subCategory: "INPUT", variable: "WARNING_LIMIT_LOWER_ACTIVE" },
  { category: "QUANTITIES", subCategory: "PROCESSING_RATE", variable: "MEASUREMENT" },
  { category: "QUANTITIES", subCategory: "PROCESSING_RATE", variable: "ALARM_LIMIT_LOWER" },
  { category: "QUANTITIES", subCategory: "PROCESSING_RATE", variable: "ALARM_LIMIT_LOWER_ACTIVE" },
  { category: "QUANTITIES", subCategory: "PROCESSING_RATE", variable: "WARNING_LIMIT_LOWER" },
  { category: "QUANTITIES", subCategory: "PROCESSING_RATE", variable: "WARNING_LIMIT_LOWER_ACTIVE" },
  { category: "DRYING", subCategory: "BURNER_PERFORMANCE", variable: "MEASUREMENT" },
  { category: "DRYING", subCategory: "BURNER_PERFORMANCE", variable: "ALARM_LIMIT_UPPER" },
  { category: "DRYING", subCategory: "BURNER_PERFORMANCE", variable: "ALARM_LIMIT_UPPER_ACTIVE" },
  { category: "DRYING", subCategory: "BURNER_PERFORMANCE", variable: "ALARM_LIMIT_LOWER" },
  { category: "DRYING", subCategory: "BURNER_PERFORMANCE", variable: "ALARM_LIMIT_LOWER_ACTIVE" },
  { category: "DRYING", subCategory: "BURNER_PERFORMANCE", variable: "WARNING_LIMIT_UPPER" },
  { category: "DRYING", subCategory: "BURNER_PERFORMANCE", variable: "WARNING_LIMIT_UPPER_ACTIVE" },
  { category: "DRYING", subCategory: "BURNER_PERFORMANCE", variable: "WARNING_LIMIT_LOWER" },
  { category: "DRYING", subCategory: "BURNER_PERFORMANCE", variable: "WARNING_LIMIT_LOWER_ACTIVE" },
  { category: "ENERGY_MANAGEMENT", subCategory: "PERFORMANCE", variable: "MEASUREMENT" },
  { category: "ENERGY_MANAGEMENT", subCategory: "PERFORMANCE", variable: "ALARM_LIMIT_UPPER" },
  { category: "ENERGY_MANAGEMENT", subCategory: "PERFORMANCE", variable: "ALARM_LIMIT_UPPER_ACTIVE" },
  { category: "ENERGY_MANAGEMENT", subCategory: "PERFORMANCE", variable: "WARNING_LIMIT_UPPER" },
  { category: "ENERGY_MANAGEMENT", subCategory: "PERFORMANCE", variable: "WARNING_LIMIT_UPPER_ACTIVE" },
  { category: "ENERGY_MANAGEMENT", subCategory: "COMPRESSED_AIR_QUANTITY", variable: "MEASUREMENT" },
  { category: "ENERGY_MANAGEMENT", subCategory: "COMPRESSED_AIR_QUANTITY", variable: "ALARM_LIMIT_UPPER" },
  { category: "ENERGY_MANAGEMENT", subCategory: "COMPRESSED_AIR_QUANTITY", variable: "ALARM_LIMIT_UPPER_ACTIVE" },
  { category: "ENERGY_MANAGEMENT", subCategory: "COMPRESSED_AIR_QUANTITY", variable: "ALARM_LIMIT_LOWER" },
  { category: "ENERGY_MANAGEMENT", subCategory: "COMPRESSED_AIR_QUANTITY", variable: "ALARM_LIMIT_LOWER_ACTIVE" },
  { category: "ENERGY_MANAGEMENT", subCategory: "COMPRESSED_AIR_QUANTITY", variable: "WARNING_LIMIT_UPPER" },
  { category: "ENERGY_MANAGEMENT", subCategory: "COMPRESSED_AIR_QUANTITY", variable: "WARNING_LIMIT_UPPER_ACTIVE" },
  { category: "ENERGY_MANAGEMENT", subCategory: "COMPRESSED_AIR_QUANTITY", variable: "WARNING_LIMIT_LOWER" },
  { category: "ENERGY_MANAGEMENT", subCategory: "COMPRESSED_AIR_QUANTITY", variable: "WARNING_LIMIT_LOWER_ACTIVE" }
]

// Helper function to generate query parts
const generateQueryPart = ({ category, subCategory, variable }: QueryParam) => `
  {
    from: "${LAST_VALUE_START_DATE}"
    to: $to
    category: ${category}
    subCategory: ${subCategory}
    variable: ${variable}
    index: 1
    variableIndex: 1
    limit: "1"
    order: Descending
  }
`

// Generate the data array for the bulkData query
const dataQueries = QUERY_PARAMS.map((param) =>
  generateQueryPart({ ...param }),
)

export const QUERY_FOR_OVERVIEW = `
  query overview($deviceId: String!, $to: DateTime!) {
    bulkData(
      deviceId: $deviceId
      data: [${dataQueries.join(',\n')}]
    ) {
      values {
        value
        timestamp
      }
    }
  }
`
