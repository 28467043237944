import React from 'react'
import IValidationErrorMessageProps from './ValidationErrorMessage.interface'
import styles from './ValidationErrorMessage.module.scss'

const ValidationErrorMessage = ({ error }: IValidationErrorMessageProps) => (
  <div className={styles.validationErrorMessage__container}>
    <span className={styles.validationErrorMessage__text}>{error}</span>
  </div>
)

export default ValidationErrorMessage
