import ButtonComponent from 'components/Button/Button'
import InputComponent from 'components/Input/Input'
import ValidationErrorMessage from 'components/ValidationErrorMessage/ValidationErrorMessage'
import useLoginForm from 'hooks/useLoginForm'
import usePlsTranslation from 'hooks/usePlsTranslation'
import React from 'react'
import styles from './Form.module.scss'

const Form = () => {
  const { translate } = usePlsTranslation()
  const { errorMessage, handleFieldChange, handleLoginFormSubmission } = useLoginForm()

  return (
    <div className={styles.form__container}>
      <form className={styles.form__form} onSubmit={handleLoginFormSubmission}>
        <InputComponent
          label={translate('email')}
          onChange={({ target: { value } }: { target: { value: string } }) => handleFieldChange({ fieldName: 'email', newValue: value })}
        />
        <InputComponent
          label={translate('password')}
          onChange={({ target: { value } }: { target: { value: string } }) => handleFieldChange({ fieldName: 'password', newValue: value })}
          type="password"
        />
        <ButtonComponent
          className={styles.form__button}
          text={translate('submit')}
          type="submit"
        />
        {errorMessage && <ValidationErrorMessage error={errorMessage} />}
      </form>
    </div>
  )
}

export default Form
