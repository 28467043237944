import ChartStatisticsComponent from 'components/ChartStatistics/ChartStatistics'
import ChartTitleComponent from 'components/ChartTitle/ChartTitle'
import { BOX_WIDTH } from 'constants/box'
import DeviceContext from 'contexts/device'
import useBox from 'hooks/useBox'
import React, { useContext } from 'react'
import IBoxProps from './Box.interface'
import styles from './Box.module.scss'

const Box = ({
  children,
  displayTitleDetails,
  machineCount,
  name: boxName,
  number = 1,
  selectedMachineId,
  selectedTime,
  selectedTimePeriod,
  width,
  yAxisRange,
  index = 0, // this is the index for e.g.. output 1
}: IBoxProps) => {
  const { deviceId } = useContext(DeviceContext)
  const { renderBoxBody } = useBox({
    boxName,
    displayTitleDetails: displayTitleDetails || displayTitleDetails,
    machineCount,
    number,
    selectedDeviceId: deviceId,
    selectedMachineId: selectedMachineId || 0,
    selectedTime: selectedTime || '',
    selectedTimePeriod: selectedTimePeriod || '',
    yAxisRange,
    index,
  })
  const boxBody = renderBoxBody()
  return (
    <div className={styles.box__container} style={{ width }}>
      <ChartTitleComponent
        title={boxBody?.title || ''}
        titleDetails={boxBody?.titleDetails}
      />
      {boxBody?.statistics?.data && (
        <ChartStatisticsComponent
          classNameModifier="onQuantityIndicationsPage"
          statistics={boxBody.statistics}
        />
      )}
      <div
        className={`${styles.box__contentContainer} ${
          styles[`box__contentContainer--${boxName}`]
        }`}
      >
        {children}
        {boxBody?.content}
      </div>
    </div>
  )
}

Box.defaultProps = {
  children: null,
  displayTitleDetails: true,
  width: BOX_WIDTH.NARROW,
  index: 0,
}

export default Box
