import Card from 'components/Card/Card'
import ChartComponent from 'components/Chart/Chart'
import LoaderComponent from 'components/Loader/Loader'
import SimpleChartComponent from 'components/SimpleChart/SimpleChart'
import DeviceContext from 'contexts/device'
import useOverviewChartBox from 'hooks/useOverviewChartBox'
import usePlsTranslation from 'hooks/usePlsTranslation'
import React, { useContext } from 'react'
import { getConfigItemValue } from 'utils/config'
import styles from './Overview.module.scss'

const Overview = () => {
  const { translate } = usePlsTranslation()
  const { deviceId } = useContext(DeviceContext)
  const { loading, renderOverviewChartBoxProps, simpleChartsConfig } =
    useOverviewChartBox({
      deviceId,
    })

  const {
    legend,
    series,
    title,
    titleDetails,
    tooltipData,
    tooltipRenderer,
    xAxisType,
    yAxisType,
  } = renderOverviewChartBoxProps()

  return (
    <Card
      title={title}
      titleDetails={
        // eslint-disable-next-line no-nested-ternary
        getConfigItemValue('dashboard.overview.runtimeAnalysis.enabled') ? (
          !series.data.length && series.loading ? (
            <LoaderComponent style={{ width: '100px' }} />
          ) : (
            titleDetails
          )
        ) : (
          <></>
        )
      }
    >
      <div className={styles.useBoxOverviewContent}>
        {getConfigItemValue('dashboard.overview.runtimeAnalysis.enabled') && (
          <>
            <p className={styles.useBoxOverviewContent__title}>
              {translate(
                getConfigItemValue(
                  'dashboard.overview.runtimeAnalysis.translation',
                ),
              )}
            </p>
            <p
              className={`${styles.useBoxOverviewContent__title} ${styles['useBoxOverviewContent__title--subtitle']}`}
            >
              {translate('lastNHours', {
                hoursQuantity: +getConfigItemValue(
                  'dashboard.overview.runtimeAnalysis.range',
                ),
              })}
            </p>
            <ChartComponent
              height="120px"
              hideXAxisLabel
              legend={legend}
              series={series}
              tooltipData={tooltipData}
              tooltipRenderer={tooltipRenderer}
              tooltipTrigger="item"
              xAxisType={xAxisType}
              yAxisType={yAxisType}
            />
            <hr className={styles.useBoxOverview__separator} />
          </>
        )}
        <div className={styles.useBoxOverview__simpleChartsContainer}>
          {simpleChartsConfig.map((simpleChartConfig) => (
            <SimpleChartComponent
              alarm={simpleChartConfig.alarm}
              key={simpleChartConfig.name}
              loading={loading}
              maxValue={simpleChartConfig.maxValue}
              name={simpleChartConfig.name}
              onClick={simpleChartConfig.onClick}
              unit={simpleChartConfig.unit}
              value={simpleChartConfig.value}
              warning={simpleChartConfig.warning}
            />
          ))}
        </div>
      </div>
    </Card>
  )
}

export default Overview
