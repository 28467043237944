import Drying from 'components/dashboard/Drying/Drying'
import Overview from 'components/dashboard/Overview/Overview'
import QuantityIndication from 'components/dashboard/QuantityIndication/QuantityIndication'
import SortingMachines from 'components/dashboard/SortingMachines/SortingMachines'
import { BOX_NAME } from 'constants/box'
import DeviceContext from 'contexts/device'
import React, { useContext } from 'react'
import { getConfigItemValue } from 'utils/config'
import styles from './index.module.scss'

const DashboardPage = () => {
  const { deviceId } = useContext(DeviceContext)

  return (
    <div className={styles.dashboard__container}>
      <div className={styles.dashboard__row}>
        {getConfigItemValue('dashboard.overview.enabled') && <Overview />}
        {getConfigItemValue('dashboard.quantityIndications.enabled') && (
          <QuantityIndication name={BOX_NAME.QUANTITY_INDICATIONS_DASHBOARD} />
        )}
      </div>
      <div className={styles.dashboard__row}>
        {getConfigItemValue('dashboard.sortingMachines.enabled') && (
          <SortingMachines deviceID={deviceId} />
        )}
        {getConfigItemValue('dashboard.drying.enabled') && (
          <Drying deviceID={deviceId} />
        )}
      </div>
    </div>
  )
}

export default DashboardPage
