import React from 'react'
import IInputProps from './Input.interface'
import styles from './Input.module.scss'

const InputComponent: React.FC<IInputProps> = ({
  label,
  onChange,
  type = 'text',
}) => {
  const id = label

  return (
    <div className={styles.input__container}>
      <label className={styles.input__label} htmlFor={id}>
        {label}
      </label>
      <input
        className={styles.input__input}
        id={id}
        onChange={onChange}
        type={type}
      />
    </div>
  )
}

export default InputComponent
