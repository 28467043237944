import AUTH from 'constants/auth'
import MENU from 'constants/menu'
import { DEVICES_LIST } from 'constants/storage'
import { getConfigItemValue } from 'utils/config'
import useCurrentDate from 'hooks/useCurrentDate'
import React, { useEffect, useState, useContext } from 'react'
import DeviceContext from 'contexts/device'
import { useNavigate } from 'react-router-dom'

export interface IDeviceProps {
  id: number
  text: string
}
interface IMachineProps {
  id: number
  text: string
}

export default () => {
  const navigate = useNavigate()
  const { deviceId } = useContext(DeviceContext)

  const [devicesList, setDevicesList] = useState<IDeviceProps[]>([])
  const [isMenuHidden, setIsMenuHidden] = useState(true)
  const [isSidebarFixed, setIsSidebarFixed] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState<IDeviceProps>({
    id: 0,
    text: '',
  })
  const [selectedMachine, setSelectedMachine] = useState<IMachineProps>({
    id: 0,
    text: '',
  })
  const [selectedTime, setSelectedTime] = useState('')

  const machinesList = getConfigItemValue('sortingMachines.elements').map((machine: any, id: number) => { return { id: id + 1, text: machine.title } })

  useEffect(() => {
    const token = localStorage.getItem(AUTH.TOKEN)
    if (!token) {
      navigate(MENU.LOGIN.HREF)
    }
  }, [])
  useEffect(() => {
    const { year, month, day } = useCurrentDate()
    setSelectedTime(`${year}-${month}-${day}`)
  }, [])
  useEffect(() => {
    setDevicesList(JSON.parse(localStorage.getItem(DEVICES_LIST) || '{}'))
  }, [])
  useEffect(() => {
    setSelectedMachine(machinesList[0])
  }, [deviceId])
  useEffect(() => {
    const handleScroll = () => setIsSidebarFixed(window.scrollY > 116)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  useEffect(() => {
    if (devicesList?.[0]) {
      setSelectedDevice(devicesList[0])
    }
  }, [devicesList])

  const handleGoBackClick = () => navigate(-1)
  const handleMachineChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMachine(() => {
      return {
        id: +event.target.value,
        text: event.target[event.target.selectedIndex].label,
      }
    })
  }
  const hideMenu = () => setIsMenuHidden(true)
  const toggleMenuVisibility = () => setIsMenuHidden((isHidden) => !isHidden)

  return {
    devicesList,
    handleGoBackClick,
    handleMachineChange,
    hideMenu,
    isMenuHidden,
    isSidebarFixed,
    machinesList,
    selectedDevice,
    selectedMachine,
    selectedTime,
    setSelectedTime,
    toggleMenuVisibility,
  }
}
