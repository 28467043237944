import ChartStatisticComponent from 'components/ChartStatistic/ChartStatistic'
import React from 'react'
import { IChartStatisticsStatistics } from './ChartStatistics.interface'
import styles from './ChartStatistics.module.scss'

const ChartStatisticsComponent = ({
  classNameModifier,
  statistics,
}: IChartStatisticsStatistics) => (
  <div
    className={`${styles.chartStatistics__container} ${
      styles[`chartStatistics__container--${classNameModifier}`]
    }`}
  >
    {statistics.data.map((details) => (
      <ChartStatisticComponent
        details={details}
        key={details.name}
        loading={statistics?.loading}
      />
    ))}
  </div>
)

export default ChartStatisticsComponent
