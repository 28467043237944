import ChartTitleComponent from 'components/ChartTitle/ChartTitle'
import { BOX_WIDTH } from 'constants/box'
import React from 'react'
import CardInterface from './Card.interface'
import styles from './Card.module.scss'

const Card = ({
  children,
  name,
  title,
  titleDetails,
  width = BOX_WIDTH.NARROW,
}: CardInterface) => (
  <div className={styles.box__container} style={{ width }}>
    <ChartTitleComponent title={title} titleDetails={titleDetails} />
    <div
      className={`${styles.box__contentContainer} ${
        styles[`box__contentContainer--${name}`]
      }`}
    >
      {children}
    </div>
  </div>
)

export default Card
