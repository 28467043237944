import Box from 'components/Box/Box'
import { BOX_NAME, BOX_WIDTH } from 'constants/box'
import usePlsTranslation from 'hooks/usePlsTranslation'
import React from 'react'
import { getConfigItemValue } from 'utils/config'
import styles from './index.module.scss'

interface IProductionQuantitiesPageProps {
  selectedTime?: string
  selectedTimePeriod?: string
}

const ProductionQuantitiesPage = ({
  selectedTime,
  selectedTimePeriod,
}: IProductionQuantitiesPageProps) => {
  const { translate } = usePlsTranslation()

  return (
    <div className={styles.productionQuantitiesPage__container}>
      {getConfigItemValue('productionQuantities.processingRate.enabled') &&
        selectedTimePeriod === translate('timePeriod.day') && (
          <Box
            name={BOX_NAME.PROCESSING_RATE_DAILY}
            selectedTime={selectedTime}
            selectedTimePeriod={selectedTimePeriod}
            width={BOX_WIDTH.FULL}
          />
        )}
      {getConfigItemValue('productionQuantities.quantityIndications.enabled') &&
        selectedTimePeriod === translate('timePeriod.day') && (
          <Box
            displayTitleDetails={false}
            name={BOX_NAME.QUANTITY_INDICATIONS}
            selectedTime={selectedTime}
            selectedTimePeriod={selectedTimePeriod}
            width={BOX_WIDTH.FULL}
          />
        )}
      {(selectedTimePeriod === translate('timePeriod.week') ||
        selectedTimePeriod === translate('timePeriod.month') ||
        selectedTimePeriod === translate('timePeriod.year')) && (
        <>
          {getConfigItemValue(
            'productionQuantities.processingRate.enabled',
          ) && (
            <Box
              name={BOX_NAME.PROCESSING_RATE}
              selectedTime={selectedTime}
              selectedTimePeriod={selectedTimePeriod}
              width={BOX_WIDTH.FULL}
            />
          )}
          <Box
            name={BOX_NAME.PRODUCTION_QUANTITIES_INPUT}
            selectedTime={selectedTime}
            selectedTimePeriod={selectedTimePeriod}
            width={BOX_WIDTH.FULL}
          />
          <Box
            name={BOX_NAME.PRODUCTION_QUANTITIES_DRYING}
            selectedTime={selectedTime}
            selectedTimePeriod={selectedTimePeriod}
            width={BOX_WIDTH.FULL}
          />
          {getConfigItemValue(
            'productionQuantities.quantityIndications.outputElements',
          ).map((outputElement: any, index: number) => (
            <Box
              key={outputElement.translation}
              name={BOX_NAME.PRODUCTION_QUANTITIES_OUTPUT}
              selectedTime={selectedTime}
              selectedTimePeriod={selectedTimePeriod}
              width={BOX_WIDTH.FULL}
              index={index}
            />
          ))}
        </>
      )}
    </div>
  )
}

ProductionQuantitiesPage.defaultProps = {
  selectedTime: '',
  selectedTimePeriod: '',
}

export default ProductionQuantitiesPage
