import Box from 'components/Box/Box'
import { BOX_NAME, BOX_WIDTH } from 'constants/box'
import React from 'react'
import { getConfigItemValue } from 'utils/config'
import styles from './index.module.scss'

interface IDryingPageProps {
  selectedTime?: string
  selectedTimePeriod?: string
}

const DryingPage = ({ selectedTime, selectedTimePeriod }: IDryingPageProps) => (
  <div className={styles.dryingPage__container}>
    {getConfigItemValue('drying.burnerPerformance.enabled') && (
      <Box
        name={BOX_NAME.BURNER_PERFORMANCE}
        selectedTime={selectedTime}
        selectedTimePeriod={selectedTimePeriod}
        width={BOX_WIDTH.FULL}
      />
    )}
    {getConfigItemValue('drying.temperatures.enabled') && (
      <Box
        name={BOX_NAME.TEMPERATURES}
        selectedTime={selectedTime}
        selectedTimePeriod={selectedTimePeriod}
        width={BOX_WIDTH.FULL}
      />
    )}
  </div>
)

DryingPage.defaultProps = {
  selectedTime: '',
  selectedTimePeriod: '',
}

export default DryingPage
