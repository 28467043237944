import { ISeriesDataProps } from 'components/ChartBox/ChartBox.interface'
import formatNumber from 'utils/common'

interface IUseChartProps {
  hideXAxisLabel?: boolean
  legend?: any
  series: { data: ISeriesDataProps[] }
  tooltipData?: any[]
  tooltipRenderer?: Function
  tooltipTrigger?: string
  unit: string
  xAxisType: string
  yAxisType: string
  yAxisValueMax?: number
  yAxisValueMin?: number
}

export default ({
  hideXAxisLabel = false,
  legend,
  series: { data: seriesData },
  tooltipData = [],
  tooltipRenderer = () => {},
  tooltipTrigger = 'axis',
  unit,
  xAxisType,
  yAxisType,
  yAxisValueMax,
  yAxisValueMin,
}: IUseChartProps) => {
  const option = {
    grid: {
      bottom: '3%',
      containLabel: true,
      left: '3%',
      right: '4%',
    },
    legend: {
      data: seriesData.map((serie) => serie.name),
      top: legend.top,
    },
    series: seriesData.map((serie) => {
      return {
        color: serie.color,
        data: (serie.data || []).map((data) => data.value),
        markLine: serie.markLine,
        name: serie.name,
        stack: serie.stack,
        type: serie.type,
      }
    }),
    title: {
      show: false,
    },
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: tooltipTrigger,
      position: 'top',
      confine: true,
      formatter: (params: any) => {
        let message = ''
        if (tooltipData.length === 0) {
          message += `${formatNumber(params[0].axisValueLabel)}`
          params.forEach((param: any) => {
            message += `<br/>${param.marker}${
              param.seriesName
            }: <strong>${formatNumber(param.value)} ${unit}</strong>`
          })
          return message
        }
        return tooltipRenderer(tooltipData[params.seriesIndex])
      },
    },
    xAxis: {
      data: (seriesData?.[0]?.data || []).map((data) => data.xAxisData).flat(),
      interval: hideXAxisLabel ? 0 : 1,
      type: xAxisType,
    },
    yAxis: {
      axisLabel: {
        formatter: (yValue: number) => formatNumber(yValue),
      },
      data: [''],
      max: yAxisValueMax,
      min: yAxisValueMin,
      type: yAxisType,
    },
  }

  return {
    option,
  }
}
