import MENU from 'constants/menu'
import usePlsTranslation from 'hooks/usePlsTranslation'
import { renderMenuItemsFromConfig } from 'utils/config'

interface IMenuItemProps {
  href: string
  icon: string
  text: string
}

export default () => {
  const { translate } = usePlsTranslation()

  const bottomMenuItems = [
    {
      icon: 'arrowRightFromBracket',
      href: MENU.SIGN_OUT.HREF,
      text: translate('menuItem.signOut'),
    },
  ]

  const mainMenuItems = renderMenuItemsFromConfig().map(
    (menuItem: IMenuItemProps) => {
      return {
        ...menuItem,
        text: translate(menuItem.text),
      }
    },
  )

  return {
    bottomMenuItems,
    mainMenuItems,
  }
}
