import { gql, useQuery } from '@apollo/client'
import TitleDetailsComponent from 'components/TitleDetails/TitleDetails'
import CHART_TYPES from 'constants/chartTypes'
import moment from 'moment'
import {
  QUERY_FOR_ENERGY_CURRENT_DAILY,
  QUERY_FOR_ENERGY_PREV_DAILY,
} from 'graphql/queries'
import usePlsTranslation from 'hooks/usePlsTranslation'
import React, { useMemo, useState, useEffect } from 'react'
import {
  fillChartFutureValuesWithZeros,
  formatXAxis,
  renderColumnsQuantity,
  renderTimeRange,
  timeRangeIncludesToday,
} from 'utils/charts'
import formatNumber from 'utils/common'
import { getConfigItemValue } from 'utils/config'

interface IUseChartBoxProps {
  deviceId: number
  selectedTime: string
}

export default ({ deviceId, selectedTime }: IUseChartBoxProps) => {
  const { translate } = usePlsTranslation()
  const { from, to } = renderTimeRange(selectedTime)
  const includesToday = timeRangeIncludesToday(selectedTime)

  const [preparedGasData, setPreparedGasData] = useState<
    { value: number; xAxisData: string }[]
  >([])

  const [preparedPowerData, setPreparedPowerData] = useState<
    { value: number; xAxisData: string }[]
  >([])

  const [preparedAirData, setPreparedAirData] = useState<
    { value: number; xAxisData: string }[]
  >([])

  const prepareQuery = (queryString: string) =>
    useMemo(() => {
      const preparedQueryString = queryString
        .replaceAll(
          '{{{calculationTimeRange}}}',
          String(renderColumnsQuantity(selectedTime)),
        )
        .replaceAll('{{{deviceId}}}', String(deviceId))
        .replaceAll('{{{from}}}', moment(from).toISOString())
        .replaceAll('{{{to}}}', moment(to).add(1, 'day').toISOString())
      return gql`
        ${preparedQueryString}
      `
    }, [deviceId, from, queryString, to])

  const prepareQueryToday = (queryString: string) =>
    useMemo(() => {
      const preparedQueryString = queryString
        .replaceAll('{{{deviceId}}}', String(deviceId))
        .replaceAll('{{{from}}}', moment().startOf('day').toISOString())
        .replaceAll('{{{to}}}', moment().endOf('day').toISOString())
      return gql`
        ${preparedQueryString}
      `
    }, [deviceId, from, queryString, to])

  const { data: energyDataDaily, loading: energyDataDailyLoading } = useQuery(
    prepareQuery(QUERY_FOR_ENERGY_PREV_DAILY),
    {
      fetchPolicy: includesToday ? 'network-only' : 'cache-first',
    },
  )

  const { data: energyDataToday, loading: energyDataTodayLoading } = useQuery(
    prepareQueryToday(QUERY_FOR_ENERGY_CURRENT_DAILY),
    {
      fetchPolicy: includesToday ? 'network-only' : 'cache-first',
    },
  )

  const prepareData = (
    queryData: {
      timestamp: string
      value: number
    }[],
  ) => {
    const arrayWithData = queryData || []
    return arrayWithData.map((details) => {
      return {
        value: Math.round(details.value * 100) / 100,
        xAxisData: formatXAxis({ selectedTime, timestamp: details.timestamp }),
      }
    })
  }

  useEffect(() => {
    const gasDaily: { value: number; timestamp: string }[] = energyDataDaily?.gas
    const powerDaily: { value: number; timestamp: string }[] = energyDataDaily?.power
    const airDaily: { value: number; timestamp: string }[] = energyDataDaily?.airPressure

    if (
      includesToday
      && !energyDataTodayLoading
      && !energyDataDailyLoading
      && energyDataToday
      && energyDataDaily
    ) {
      const gasToday: { value: number; timestamp: string }[] = energyDataToday.gas
      const powerToday: { value: number; timestamp: string }[] = energyDataToday.power
      const airToday: { value: number; timestamp: string }[] = energyDataToday.airPressure

      // fake input data timing to always apply the prev day logic
      gasToday[0].timestamp = moment()
        .add(1, 'days')
        .startOf('day')
        .toISOString()

      powerToday[0].timestamp = moment()
        .add(1, 'days')
        .startOf('day')
        .toISOString()

      airToday[0].timestamp = moment()
        .add(1, 'days')
        .startOf('day')
        .toISOString()  

      const power = powerDaily.concat(powerToday)
      const gas = gasDaily.concat(gasToday)
      const air = airDaily.concat(airToday)

      setPreparedPowerData(prepareData(power))
      setPreparedGasData(prepareData(gas))
      setPreparedAirData(prepareData(air))
    } else if (!includesToday && !energyDataDailyLoading) {
      setPreparedPowerData(prepareData(powerDaily))
      setPreparedGasData(prepareData(gasDaily))
      setPreparedAirData(prepareData(airDaily))
    }
  }, [energyDataToday, energyDataDaily])

  const renderGasConsumptionChartBoxProps = ({
    selectedTimePeriod,
    unit,
  }: {
    selectedTimePeriod: string
    unit: string
  }) => {
    const preparedData = fillChartFutureValuesWithZeros({
      preparedData: preparedGasData,
      selectedTime,
    })
    const sum = Math.round(
      preparedData.map((data) => data.value).reduce((a, b) => +a + +b, 0),
    )
    return {
      series: {
        data: [
          {
            color: getConfigItemValue('energyManagement.gasConsumption.color'),
            data: preparedData,
            name: translate(
              getConfigItemValue('energyManagement.gasConsumption.translation'),
            ),
            type:
              selectedTimePeriod === translate('timePeriod.month')
              || selectedTimePeriod === translate('timePeriod.year')
                ? CHART_TYPES.BAR
                : CHART_TYPES.LINE,
          },
        ],
        loading: energyDataDailyLoading || energyDataTodayLoading,
      },
      title: translate(
        getConfigItemValue('energyManagement.gasConsumption.translation'),
      ),
      titleDetails: (
        <TitleDetailsComponent
          iconName="sum"
          value={`${formatNumber(sum)} ${unit}`}
        />
      ),
    }
  }
  const renderPerformanceChartBoxProps = ({
    selectedTimePeriod,
    unit,
  }: {
    selectedTimePeriod: string
    unit: string
  }) => {
    const preparedData = fillChartFutureValuesWithZeros({
      preparedData: preparedPowerData,
      selectedTime,
    })
    const sum = Math.round(
      preparedData.map((data) => data.value).reduce((a, b) => +a + +b, 0),
    )
    return {
      series: {
        data: [
          {
            color: getConfigItemValue('energyManagement.performance.color'),
            data: preparedData,
            name: translate(
              getConfigItemValue('energyManagement.performance.translation'),
            ),
            type:
              selectedTimePeriod === translate('timePeriod.month')
              || selectedTimePeriod === translate('timePeriod.year')
                ? CHART_TYPES.BAR
                : CHART_TYPES.LINE,
          },
        ],
        loading: energyDataDailyLoading || energyDataTodayLoading,
      },
      title: translate(
        getConfigItemValue('energyManagement.performance.translation'),
      ),
      titleDetails: (
        <TitleDetailsComponent
          iconName="sum"
          value={`${formatNumber(sum)} ${unit}`}
        />
      ),
    }
  }
  const renderAirPressureChartBoxProps = ({
    selectedTimePeriod,
    unit,
  }: {
    selectedTimePeriod: string
    unit: string
  }) => {
    const preparedData = fillChartFutureValuesWithZeros({
      preparedData: preparedAirData,
      selectedTime,
    })
    const sum = Math.round(
      preparedData.map((data) => data.value).reduce((a, b) => +a + +b, 0),
    )
    return {
      series: {
        data: [
          {
            color: getConfigItemValue('energyManagement.airPressure.color'),
            data: preparedData,
            name: translate(
              getConfigItemValue('energyManagement.airPressure.translation'),
            ),
            type:
              selectedTimePeriod === translate('timePeriod.month')
              || selectedTimePeriod === translate('timePeriod.year')
                ? CHART_TYPES.BAR
                : CHART_TYPES.LINE,
          },
        ],
        loading: energyDataDailyLoading || energyDataTodayLoading,
      },
      title: translate(
        getConfigItemValue('energyManagement.airPressure.translation'),
      ),
      titleDetails: (
        <TitleDetailsComponent
          iconName="sum"
          value={`${formatNumber(sum)} ${unit}`}
        />
      ),
    }
  }

  return {
    renderGasConsumptionChartBoxProps,
    renderPerformanceChartBoxProps,
    renderAirPressureChartBoxProps,
  }
}
