import { useLocation } from 'react-router-dom'
import usePlsTranslation from 'hooks/usePlsTranslation'
import Header from 'components/Header/Header'
import Sidebar from 'components/Sidebar/Sidebar'
import TimePeriodSelectorComponent from 'components/TimePeriodSelector/TimePeriodSelector'
import TopBar from 'components/TopBar/TopBar'
import useLayout from 'hooks/useLayout'
import React, { useState, useEffect } from 'react'
import ILayoutProps from './LayoutAfterLogin.interface'
import styles from './LayoutAfterLogin.module.scss'

const LayoutAfterLogin = ({
  children,
  displayMachineSelector = false,
  displayTimePeriodSelector,
  displayTimePeriodsSwitch = true,
}: ILayoutProps) => {
  const { translate } = usePlsTranslation()
  const location = useLocation()

  const [selectedTimePeriod, setSelectedTimePeriod] = useState(
    translate('timePeriod.day'),
  )

  useEffect(() => {
    setSelectedTimePeriod(translate('timePeriod.day'))
  }, [location])

  const {
    devicesList,
    handleGoBackClick,
    handleMachineChange,
    hideMenu,
    isMenuHidden,
    isSidebarFixed,
    machinesList,
    selectedDevice,
    selectedMachine,
    selectedTime,
    setSelectedTime,
    toggleMenuVisibility,
  } = useLayout()

  return (
    <div className={styles.layoutAfterLogin__container}>
      <Header devicesList={devicesList} />
      <TopBar
        displayMachineSelector={displayMachineSelector}
        handleGoBackClick={handleGoBackClick}
        handleMachineChange={handleMachineChange}
        machinesList={machinesList}
        selectedMachine={selectedMachine}
        toggleMenuVisibility={toggleMenuVisibility}
      />
      <Sidebar
        fixed={isSidebarFixed}
        hideMenu={hideMenu}
        isMenuHidden={isMenuHidden}
      />
      <div className={styles.layoutAfterLogin__content}>
        {displayTimePeriodSelector && (
          <TimePeriodSelectorComponent
            displayTimePeriodsSwitch={displayTimePeriodsSwitch}
            selectedTime={selectedTime}
            selectedTimePeriod={selectedTimePeriod}
            setSelectedTime={setSelectedTime}
            setSelectedTimePeriod={setSelectedTimePeriod}
          />
        )}
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              selectedDeviceId: selectedDevice.id,
              selectedMachineId: selectedMachine.id,
              selectedMachineName: selectedMachine.text,
              selectedTime,
              selectedTimePeriod,
            })
          }
          return child
        })}
      </div>
    </div>
  )
}

export default LayoutAfterLogin
