import Box from 'components/Box/Box'
import { BOX_NAME, BOX_WIDTH } from 'constants/box'
import usePlsTranslation from 'hooks/usePlsTranslation'
import React from 'react'
import { getConfigItemValue } from 'utils/config'
import styles from './index.module.scss'

interface IEnergyManagementProps {
  selectedTime?: string
  selectedTimePeriod?: string
}

const EnergyManagementPage = ({
  selectedTime,
  selectedTimePeriod,
}: IEnergyManagementProps) => {
  const { translate } = usePlsTranslation()

  if (selectedTimePeriod === translate('timePeriod.day')) {
    return (
      <div className={styles.energyManagementPage__container} key="1">
        {getConfigItemValue('energyManagement.performance.enabled') && (
          <Box
            name={BOX_NAME.PERFORMANCE_LINE_CHART}
            selectedTime={selectedTime}
            selectedTimePeriod={selectedTimePeriod}
            width={BOX_WIDTH.FULL}
          />
        )}
        {getConfigItemValue('energyManagement.airPressure.enabled')
         && (
         <Box
           name={BOX_NAME.AIR_PRESSURE}
           selectedTime={selectedTime}
           selectedTimePeriod={selectedTimePeriod}
           width={BOX_WIDTH.HALF}
         />
         )}
        {getConfigItemValue('energyManagement.gasConsumption.enabled') && (
          <Box
            name={BOX_NAME.GAS_CONSUMPTION_LINE_CHART}
            selectedTime={selectedTime}
            selectedTimePeriod={selectedTimePeriod}
            width={BOX_WIDTH.HALF}
          />
        )}
      </div>
    )
  }
  return (
    <div className={styles.energyManagementPage__container} key="2">
      {getConfigItemValue('energyManagement.performance.enabled') && (
      <Box
        name={BOX_NAME.PERFORMANCE_BAR_CHART}
        selectedTime={selectedTime}
        selectedTimePeriod={selectedTimePeriod}
        width={BOX_WIDTH.FULL}
      />
      )}
      {getConfigItemValue('energyManagement.airPressure.enabled') && (
      <Box
        name={BOX_NAME.AIR_PRESSURE_BAR_CHART}
        selectedTime={selectedTime}
        selectedTimePeriod={selectedTimePeriod}
        width={BOX_WIDTH.FULL}
      />
      )}
      {getConfigItemValue('energyManagement.gasConsumption.enabled') && (
      <Box
        name={BOX_NAME.GAS_CONSUMPTION_BAR_CHART}
        selectedTime={selectedTime}
        selectedTimePeriod={selectedTimePeriod}
        width={BOX_WIDTH.FULL}
      />
      )}
    </div>
  )
}

EnergyManagementPage.defaultProps = {
  selectedTime: '',
  selectedTimePeriod: '',
}

export default EnergyManagementPage
