import React from 'react'
import IChartTitleProps from './ChartTitle.interface'
import styles from './ChartTitle.module.scss'

const ChartTitleComponent: React.FC<IChartTitleProps> = ({
  title,
  titleDetails,
}) => (
  <div className={styles.chartTitle__container}>
    <span className={styles.chartTitle__title}>{title}</span>
    {titleDetails && (
      <span className={styles.chartTitle__titleDetailsContainer}>
        {titleDetails}
      </span>
    )}
  </div>
)

export default ChartTitleComponent
