import {
  ApolloClient, createHttpLink, InMemoryCache, DefaultOptions, from,
} from '@apollo/client'
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import AUTH from 'constants/auth'
import { logout } from 'utils/user'

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
}

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(AUTH.TOKEN)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }
})

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (message === 'Invalid token') {
        logout()
      }
    })
  }
})

const cache = new InMemoryCache()

const createClient = async () => {
  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  })

  return new ApolloClient({
    cache,
    link: from([authLink, errorLink, httpLink]),
    uri: process.env.REACT_APP_GRAPHQL_URL,
    defaultOptions,
  })
}

export default createClient
