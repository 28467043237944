import Form from 'components/Form/Form'
import Logo from 'components/Logo/Logo'
import React from 'react'
import styles from './Login.module.scss'

const LoginForm = () => (
  <div className={styles.loginForm__container}>
    <Logo className={styles.loginForm__logo} />
    <Form />
  </div>
)

export default LoginForm
