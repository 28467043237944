import AUTH from 'constants/auth'
import MENU from 'constants/menu'
import { DEVICES_LIST } from 'constants/storage'

export const logout = () => {
  localStorage.removeItem(AUTH.TOKEN)
  localStorage.removeItem(DEVICES_LIST)
  window.location.href = MENU.LOGIN.HREF
}

export default logout
