import IconComponent from 'components/Icon/Icon'
import React, { ReactElement } from 'react'
import styles from './TitleDetails.module.scss'

interface ITitleDetailsProps {
  iconName: string
  value: ReactElement | string
}

const TitleDetailsComponent = ({ iconName, value }: ITitleDetailsProps) => (
  <span className={styles.titleDescription__container}>
    <IconComponent iconName={iconName} />
    <span>{value}</span>
  </span>
)

export default TitleDetailsComponent
