export default {
  COLOR_1: '#5470c6',
  COLOR_2: '#91cc75',
  COLOR_3: '#fac858',
  COLOR_4: '#ee6666',
  COLOR_5: '#73c0de',
  COLOR_6: '#3ba272',
  COLOR_7: '#fc8452',
  COLOR_8: '#9a60b4',
  COLOR_9: '#ea7ccc',
}
