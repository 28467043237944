import React, { createContext } from 'react'
import { SELECTED_DEVICE_ID } from 'constants/storage'

interface IDeviceContext {
  deviceId: number
  setDeviceId?: React.Dispatch<number>
}

const defaultContent = {
  deviceId: +(localStorage.getItem(SELECTED_DEVICE_ID) || 0),
}

export default createContext<IDeviceContext>(defaultContent)
