import { DEVICES_LIST } from 'constants/storage'
import DeviceContext from 'contexts/device'
import { useContext } from 'react'
import { readItemFromStorage } from './storage'

interface IConfigProps {
  dashboard: {
    enabled: boolean
    translation: string
    overview: {
      enabled: boolean
      translation: string
      runtimeAnalysis: {
        range: number
        enabled: boolean
        translation: string
      }
      input: {
        unit: string
        enabled: boolean
        translation: string
      }
      processingRate: {
        unit: string
        enabled: boolean
        translation: string
      }
      burnerPerformance: {
        unit: string
        enabled: boolean
        translation: string
      }
      systemPerformance: {
        unit: string
        enabled: boolean
        translation: string
      }
      airPressure: {
        unit: string
        enabled: boolean
        translation: string
      }
    }
    quantityIndications: {
      enabled: boolean
      translation: string
      chartMin: number
      chartMax: number
      inputElements: {
        unitSummary: string
        unit: string
        enabled: boolean
        translation: string
        color: string
      }[]
      dryingElements: {
        unitSummary: string
        unit: string
        enabled: boolean
        translation: string
        color: string
      }[]
      outputElements: {
        unitSummary: string
        unit: string
        enabled: boolean
        translation: string
        color: string
      }[]
    }
    drying: {
      enabled: boolean
      translation: string
      elements: {
        translation: string
        unit: string
      }[]
    }
  }
  productionQuantities: {
    enabled: boolean
    translation: string
    processingRate: {
      unit: string
      unitSummary: string
      chartMin: number
      chartMax: number
      enabled: boolean
      translation: string
    }
    quantityIndications: {
      enabled: boolean
      translation: string
      chartMin: number
      chartMax: number
      inputElements: {
        unitSummary: string
        unit: string
        enabled: boolean
        translation: string
      }[]
      dryingElements: {
        unitSummary: string
        unit: string
        enabled: boolean
        translation: string
      }[]
      outputElements: {
        unitSummary: string
        unit: string
        enabled: boolean
        translation: string
      }[]
    }
  }
  drying: {
    enabled: boolean
    translation: string
    burnerPerformance: {
      chartMin: number
      chartMax: number
      unit: string
      unitSummary: string
      enabled: boolean
      translation: string
    }
    temperatures: {
      enabled: boolean
      translation: string
      chartMin: number
      chartMax: number
      elements: {
        translation: string
        unit: string
        color: string
      }[]
    }
  }
  sortingMachines: {
    enabled: boolean
    translation: string
    elements: {
      title: string
      scores1: {
        chartMin: number
        chartMax: number
        translation: string
      }
      scores2: {
        chartMin: number
        chartMax: number
        translation: string
      }
    }[]
  }
  energyManagement: {
    enabled: boolean
    translation: string
    performance: {
      enabled: boolean
      translation: string
      color: string
      chartMin: number
      chartMax: number
      unitSummary: string
      unit: string
    }
    airPressure: {
      enabled: boolean
      translation: string
      color: string
      chartMin: number
      chartMax: number
      unitSummary: string
      unit: string
    }
    gasConsumption: {
      enabled: boolean
      translation: string
      color: string
      chartMin: number
      chartMax: number
      unitSummary: string
      unit: string
    }
  }
  messageList: {
    enabled: boolean
    translation: string
    warningTypes: {
      translation: string
    }[]
    errorTypes: {
      translation: string
    }[]
  }
}

export const getConfigItemValue = (key: string) => {
  const devicesListFromStorage = readItemFromStorage({ name: DEVICES_LIST })
  const { deviceId } = useContext(DeviceContext)

  const getNestedObject = (config: IConfigProps, keyPath: string[]) => keyPath.reduce(
    (myObject: any, myKey: string) => (myObject && myObject[myKey] !== 'undefined'
      ? myObject[myKey]
      : undefined),
    config,
  )

  if (devicesListFromStorage) {
    return getNestedObject(
      JSON.parse(devicesListFromStorage).find(
        (device: any) => +device.id === +deviceId,
      ).config,
      key.split('.'),
    )
  }

  return ''
}

const renderMenuItemIcon = (key: string) => {
  switch (key) {
    case 'dashboard': {
      return 'gauge'
    }
    case 'drying': {
      return 'wind'
    }
    case 'energyManagement': {
      return 'bolt'
    }
    case 'productionQuantities': {
      return 'chart'
    }
    case 'messageList': {
      return 'checklist'
    }
    case 'sortingMachines': {
      return 'hardDrive'
    }
    default: {
      return ''
    }
  }
}

const renderMenuItemHref = (key: string) => {
  switch (key) {
    case 'dashboard': {
      return '/dashboard'
    }
    case 'drying': {
      return '/drying'
    }
    case 'energyManagement': {
      return '/energy_management'
    }
    case 'productionQuantities': {
      return '/production_quantities'
    }
    case 'messageList': {
      return '/message_list'
    }
    case 'sortingMachines': {
      return '/sorting_machines'
    }
    default: {
      return '/'
    }
  }
}

const renderMenuItemTranslationKey = (key: string) => `menuItem.${key}`

export const renderMenuItemsFromConfig = () => {
  const devicesListFromStorage = readItemFromStorage({ name: DEVICES_LIST })
  const { deviceId } = useContext(DeviceContext)

  if (devicesListFromStorage) {
    const { config } = JSON.parse(devicesListFromStorage).find(
      (device: any) => +device.id === +deviceId,
    )
    const menuItems: any = []

    Object.keys(config).forEach((myKey) => {
      if (config[myKey].enabled) {
        menuItems.push({
          href: renderMenuItemHref(myKey),
          icon: renderMenuItemIcon(myKey),
          text: renderMenuItemTranslationKey(myKey),
          rank: config[myKey].sideNavRank
        })
      }
    })

    menuItems.sort((a: { rank: number }, b: { rank: number }) => a.rank - b.rank)

    return menuItems
  }

  return []
}
