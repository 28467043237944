import { ApolloProvider } from '@apollo/client'
import LayoutAfterLogin from 'components/LayoutAfterLogin/LayoutAfterLogin'
import MENU from 'constants/menu'
import DeviceContext from 'contexts/device'
import createClient from 'graphql/apolloClient'
import DashboardPage from 'pages/dashboard'
import DryingPage from 'pages/drying'
import EnergyManagementPage from 'pages/energyManagement'
import LoginPage from 'pages/login'
import MessageListPage from 'pages/messageList'
import ProductionQuantitiesPage from 'pages/productionQuantities'
import SortingMachinesPage from 'pages/sortingMachines'
import React, { useEffect, useMemo, useState } from 'react'
import { SELECTED_DEVICE_ID } from 'constants/storage'
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom'
import { logout } from 'utils/user'

const App = () => {
  const [deviceId, setDeviceId] = useState(+(localStorage.getItem(SELECTED_DEVICE_ID) || 0) || 1)
  const [client, setClient] = useState<any>(null)

  useEffect(() => {
    if (client) return
    createClient().then((newClient) => {
      setClient(newClient)
    })
  }, [client])

  const Logout = () => {
    useEffect(() => {
      logout()
    }, [])
    return <></>
  }

  const deviceContextValues = useMemo(() => {
    return {
      deviceId,
      setDeviceId,
    }
  }, [deviceId])

  return (
    <DeviceContext.Provider value={deviceContextValues}>
      {!!client && (
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Routes>
              {/* Redirects: */}
              <Route
                element={<Navigate replace to={MENU.LOGIN.HREF} />}
                path={MENU.START.HREF}
              />
              {/* Routing: */}
              <Route
                element={(
                  <LayoutAfterLogin>
                    <DashboardPage />
                  </LayoutAfterLogin>
                )}
                path={MENU.DASHBOARD.HREF}
              />
              <Route
                element={(
                  <LayoutAfterLogin
                    displayTimePeriodSelector
                    displayTimePeriodsSwitch={false}
                  >
                    <DryingPage />
                  </LayoutAfterLogin>
                )}
                path={MENU.DRYING.HREF}
              />
              <Route
                element={(
                  <LayoutAfterLogin displayTimePeriodSelector>
                    <EnergyManagementPage />
                  </LayoutAfterLogin>
                )}
                path={MENU.ENERGY_MANAGEMENT.HREF}
              />
              <Route
                element={(
                  <LayoutAfterLogin>
                    <MessageListPage />
                  </LayoutAfterLogin>
                )}
                path={MENU.MESSAGE_LIST.HREF}
              />
              <Route
                element={(
                  <LayoutAfterLogin displayTimePeriodSelector>
                    <ProductionQuantitiesPage />
                  </LayoutAfterLogin>
                )}
                path={MENU.PRODUCTION_QUANTITIES.HREF}
              />
              <Route
                element={(
                  <LayoutAfterLogin
                    displayMachineSelector
                    displayTimePeriodSelector
                    displayTimePeriodsSwitch={false}
                  >
                    <SortingMachinesPage />
                  </LayoutAfterLogin>
                )}
                path={MENU.SORTING_MACHINES.HREF}
              />
              <Route element={<LoginPage />} path={MENU.LOGIN.HREF} />
              <Route element={<Logout />} path={MENU.SIGN_OUT.HREF} />
              {/* Handle error 404: */}
              <Route
                element={<Navigate replace to={MENU.START.HREF} />}
                path="*"
              />
            </Routes>
          </BrowserRouter>
        </ApolloProvider>
      )}
    </DeviceContext.Provider>
  )
}

export default App
