import LoginForm from 'components/Form/Login/Login'
import React from 'react'
import styles from './index.module.scss'

const LoginPage = () => (
  <div className={styles.loginPage__container}>
    <LoginForm />
  </div>
)

export default LoginPage
