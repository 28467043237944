import Menu from 'components/Menu/Menu'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import usePlsTranslation from 'hooks/usePlsTranslation'
import useSidebar from 'hooks/useSidebar'
import React from 'react'
import ISidebarProps from './Sidebar.interface'
import styles from './Sidebar.module.scss'

const Sidebar = ({ fixed, hideMenu, isMenuHidden }: ISidebarProps) => {
  const { translate } = usePlsTranslation()
  const { bottomMenuItems, mainMenuItems } = useSidebar()

  return (
    <div
      className={`${styles.sidebar} ${fixed ? styles['sidebar--fixed'] : ''} ${
        isMenuHidden ? styles['sidebar--hidden'] : ''
      }`}
    >
      <div
        className={styles.mobileClose}
        onClick={hideMenu}
        role="presentation"
      >
        <FontAwesomeIcon className={styles.mobileClose__icon} icon={faClose} />
        <span className={styles.mobileClose__text}>{translate('menu')}</span>
      </div>
      <Menu hideMenu={hideMenu} menuItems={mainMenuItems} />
      <Menu hideMenu={hideMenu} menuItems={bottomMenuItems} />
    </div>
  )
}

export default Sidebar
