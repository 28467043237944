import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styles from './Loader.module.scss'

interface ILoaderProps {
  style?: { [key: string]: string }
}

const LoaderComponent: React.FC<ILoaderProps> = ({ style }) => (
  <div className={styles.loader__container}>
    <Skeleton style={style} />
  </div>
)

LoaderComponent.defaultProps = {
  style: {},
}

export default LoaderComponent
