import Dropdown from 'components/Dropdown/Dropdown'
import IconComponent from 'components/Icon/Icon'
import useTopBar from 'hooks/useTopBar'
import React from 'react'
import ITopBarProps from './TopBar.interface'
import styles from './TopBar.module.scss'

const TopBar = ({
  displayMachineSelector,
  handleGoBackClick,
  handleMachineChange,
  machinesList,
  selectedMachine,
  toggleMenuVisibility,
}: ITopBarProps) => {
  const { onClick, renderCurrentPageName, topBarIconName } = useTopBar({
    handleGoBackClick,
    toggleMenuVisibility,
  })

  return (
    <div className={styles.topBar__container}>
      <div className={styles.topBar__containerInternal}>
        <IconComponent
          className={`${styles.topBar__icon} ${
            styles[`topBar__icon--${topBarIconName}`]
          }`}
          iconName={topBarIconName}
          onClick={onClick}
        />
        <div className={styles.topBar__containerForDropdown}>
          <p className={styles.topBar__pageName}>{renderCurrentPageName()}</p>
          {displayMachineSelector && (
            <Dropdown
              className={styles.topBar__dropdown}
              onChange={handleMachineChange}
              options={machinesList}
              selectedValue={selectedMachine.id}
            />
          )}
        </div>
        <span className={styles.topBar__ghostElement}>&nbsp;</span>
      </div>
    </div>
  )
}

export default TopBar
