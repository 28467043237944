import moment from 'moment'
import { WatchQueryFetchPolicy } from '@apollo/client'

export const renderLastDayOfMonth = ({
  month,
  year,
}: {
  month: number
  year: number
}) => {
  switch (month) {
    case 0:
    case 2:
    case 4:
    case 6:
    case 7:
    case 9:
    case 11: {
      return 31
    }
    case 3:
    case 5:
    case 8:
    case 10: {
      return 30
    }
    case 1: {
      return year % 4 === 0 ? 29 : 28
    }
    default: {
      return 0
    }
  }
}
export const fillChartFutureValuesWithZeros = ({
  preparedData,
  selectedTime,
}: {
  preparedData: { value: number; xAxisData: string }[]
  selectedTime: string
}) => {
  let myPreparedData = preparedData
  const initial: { value: number; xAxisData: string }[] = []
  const segments = selectedTime.split('-')
  // this is for months
  if (segments.length === 2) {
    for (
      let step = 1;
      step
      <= renderLastDayOfMonth({
        month: +segments[1] - 1,
        year: +segments[0],
      });
      step += 1
    ) {
      initial.push({
        value: 0,
        xAxisData: `${`0${step}`.slice(-2)}.${`0${+segments[1]}`.slice(-2)}`,
      })
    }

    myPreparedData = initial.map((initialData) => {
      const foundItem = preparedData.find(
        (prep) => prep.xAxisData === initialData.xAxisData,
      )
      if (foundItem) {
        const value = preparedData.find((data) => data.xAxisData === foundItem.xAxisData)?.value
          || 0
        return {
          ...foundItem,
          value,
        }
      }
      return { ...initialData }
    })
    return myPreparedData
  }
  // this is for years
  if (segments.length === 1) {
    return Array.from(Array(12).keys()).map((monthNumber) => {
      const step = monthNumber + 1
      const xAxisData = `${`0${String(step)}`.slice(-2)}-${segments[0]}`
      initial.push({
        value: 0,
        xAxisData,
      })
      const sum = preparedData
        .filter((prep) => prep.xAxisData === xAxisData)
        .map((prep) => prep.value)
        .reduce((a, b) => +a + +b, 0)
      return {
        value: sum,
        xAxisData,
      }
    })
  }
  // this is for the day
  Array.from(Array(24).keys()).forEach((hourNumber) => {
    const hour = hourNumber === 23 ? 0 : hourNumber + 1
    initial.push({
      value: 0,
      xAxisData: `${`0${+hour}`.slice(-2)}:00`,
    })
  })
  myPreparedData = initial.map((initialData) => {
    const foundItem = preparedData.find(
      (prep) => prep.xAxisData === initialData.xAxisData,
    )

    if (foundItem) {
      const foundHour = +initialData.xAxisData.split(':')[0]
      return {
        ...foundItem,
        xAxisData: `${foundHour === 24 ? 0 : foundHour}:00`,
      }
    }
    const myHour = +initialData.xAxisData.split(':')[0]
    return {
      ...initialData,
      xAxisData: `${myHour === 24 ? 0 : myHour}:00`,
    }
  })
  return myPreparedData
}
export const formatXAxis = ({
  selectedTime,
  timestamp,
}: {
  selectedTime: string
  timestamp: string
}) => {
  const segments = selectedTime.split('-')
  switch (segments.length) {
    case 3: {
      return moment(timestamp).format('HH:mm')
    }
    case 2: {
      // substract with 1, beacuse we are always getting data
      // from the previous day (SUM_PREV_DAY) and manipulate SUM_CURRENT_DAILY to be on the next day
      return moment(timestamp).subtract(1, 'day').format('DD.MM')
    }
    case 1: {
      // substract with 1, beacuse we are always getting data
      // from the previous day (SUM_PREV_DAY) and manipulate SUM_CURRENT_DAILY to be on the next day
      return moment(timestamp).subtract(1, 'day').format('MM-YYYY')
    }
    default: {
      return ''
    }
  }
}
export const renderColumnsQuantity = (selectedTime: string) => {
  const segments = selectedTime.split('-')
  const hour = 3600000
  switch (segments.length) {
    case 3: {
      return hour
    }
    case 2: {
      return 24 * hour
    }
    case 1: {
      return 24 * hour
    }
    default: {
      return 0
    }
  }
}
export const renderTimeRange = (selectedTime: string) => {
  let from = {
    year: 2000,
    month: 1,
    day: 1,
  }
  let to = {
    year: 3000,
    month: 12,
    day: 31,
  }
  const segments = selectedTime.split('-')
  if (segments.length === 3) {
    from = {
      ...from,
      year: +segments[0],
      month: +segments[1],
      day: +segments[2],
    }
    to = {
      ...to,
      year: +segments[0],
      month: +segments[1],
      day: +segments[2],
    }
  } else if (segments.length === 2) {
    from = {
      ...from,
      year: +segments[0],
      month: +segments[1],
    }
    to = {
      ...to,
      year: +segments[0],
      month: +segments[1],
    }
  } else if (segments.length === 1) {
    from = {
      ...from,
      year: +segments[0],
    }
    to = {
      ...to,
      year: +segments[0],
    }
  }

  const dateFrom = moment(`${from.year}/${from.month}/${from.day}`).startOf('day')
  let dateTo
  if (segments.length === 3) {
    dateTo = moment(`${to.year}/${to.month}/${to.day}`).endOf('day')
  } else {
    dateTo = moment(`${to.year}/${to.month}/${to.day}`)
    const lastDayOfMonth = dateTo.daysInMonth()
    dateTo = moment(`${to.year}/${to.month}/${lastDayOfMonth}`)
  }

  return {
    from: moment(dateFrom),
    to:  moment(dateTo),
  }
}

export const renderTodayTimeRange = () => {
  const dateTimeFrom = moment().startOf('day')
  const dateTimeTo = moment().endOf('day')
  return {
    fromToday: moment(dateTimeFrom).toDate(),
    toToday:moment(dateTimeTo).toDate(),
  }
}

export const timeRangeIncludesToday = (selectedTime: string) => {
  if (!selectedTime) return 'cache-first'
  const segments = selectedTime.split('-').length
  switch (segments) {
    case 3: // day view
      return moment().format('YYYY-MM-DD') === selectedTime
    case 2: // month
      return moment().format('YYYY-MM') === selectedTime
    case 1: // year
      return moment().format('YYYY') === selectedTime
    default:
      return 'cache-first'
  }
}

export const getFetchPolicy = (selectedTime: string) => {
  if (!selectedTime) return 'cache-first'
  const segments = selectedTime.split('-').length
  switch (segments) {
    case 3: // day view
      return moment().format('YYYY-MM-DD') === selectedTime ? 'no-cache' : 'cache-first'
    case 2: // month
      return moment().format('YYYY-MM') === selectedTime ? 'no-cache' : 'cache-first'
    case 1: // year
      return moment().format('YYYY') === selectedTime ? 'no-cache' : 'cache-first'
    default:
      return 'cache-first'
  }
}

export const isToday = (selectedTime: string) => {
  if (!selectedTime) return false
  return moment().format('YYYY-MM-DD') === selectedTime
}

export const getQueryRefetchParams = (selectedTime: string, pollInterval: number) => {
  return {
    fetchPolicy: getFetchPolicy(selectedTime) as WatchQueryFetchPolicy,
    ...(pollInterval && { pollInterval }),
    notifyOnNetworkStatusChange: true,
  }
}
