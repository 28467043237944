import React from 'react'
import IconComponent from 'components/Icon/Icon'
import LoaderComponent from 'components/Loader/Loader'
import styles from './SortingMachine.module.scss'
import ISortingMachineProps from './SortingMachine.interface'

const SortingMachine = ({
  iconModifier,
  text,
  value,
  loading = false,
}: ISortingMachineProps) => {
  const renderStatisticContent = () => {
    if (loading) {
      return (
        <div style={{ width: '30px' }}>
          <LoaderComponent style={{ height: '29px' }} />
        </div>
      )
    }
    return <p className={styles.sortingMachine__value}>{value}</p>
  }

  return (
    <>
      <div className={styles.sortingMachine__container}>
        <IconComponent
          className={`${styles.sortingMachine__icon} ${
            styles[`sortingMachine__icon--${iconModifier}`]
          }`}
          iconName="hardDrive"
        />
        <p className={styles.sortingMachine__text}>{text}</p>
        {renderStatisticContent()}
      </div>
      <hr className={styles.sortingMachine__separator} />
    </>
  )
}

export default SortingMachine
