import { useTranslation } from 'react-i18next'

export default () => {
  const { i18n, t } = useTranslation()

  const translate = (
    key: string,
    variables: { [key: string]: number | string } = {},
  ) => t(`${key}.${i18n.language}`, variables)

  return { translate }
}
