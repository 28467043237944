import { gql, useQuery } from '@apollo/client'
import ButtonComponent from 'components/Button/Button'
import CHART_TYPES from 'constants/chartTypes'
import CHART_COLORS from 'constants/chartColors'
import MENU from 'constants/menu'
import {
  QUERY_FOR_DRYING,
  QUERY_FOR_INPUT,
  QUERY_FOR_OUTPUT,
  QUERY_FOR_INPUT_CURRENT_DAILY,
  QUERY_FOR_OUTPUT_CURRENT_DAILY,
  QUERY_FOR_DRYING_CURRENT_DAILY,
  QUERY_FOR_INPUT_PREV_DAILY,
  QUERY_FOR_OUTPUT_PREV_DAILY,
  QUERY_FOR_DRYING_PREV_DAILY,
} from 'graphql/queries'
import TitleDetailsComponent from 'components/TitleDetails/TitleDetails'
import usePlsTranslation from 'hooks/usePlsTranslation'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  fillChartFutureValuesWithZeros,
  formatXAxis,
  renderColumnsQuantity,
  renderTimeRange,
  getQueryRefetchParams,
  isToday,
} from 'utils/charts'
import formatNumber from 'utils/common'
import { getConfigItemValue } from 'utils/config'
import moment from 'moment'

interface IUseChartBoxProps {
  deviceId: number
  selectedTime: string
  pollInterval?: number
}

export default ({
  deviceId,
  selectedTime,
  pollInterval = 0,
}: IUseChartBoxProps) => {
  const { translate } = usePlsTranslation()
  const history = useNavigate()
  const { from, to } = renderTimeRange(selectedTime)
  const outputElements = getConfigItemValue(
    'productionQuantities.quantityIndications.outputElements',
  )
  const numberOfOutputs = outputElements.length

  const prepareQuery = (queryString: string, usePrevDaily?: boolean) =>
    useMemo(() => {
      const segments = selectedTime.split('-')
      const aggregation = segments.length === 3 ? 'TimeWeightedAverage' : 'Max'
      let preparedQueryString = queryString
        .replaceAll('{{{aggregation}}}', aggregation)
        .replaceAll(
          '{{{calculationTimeRange}}}',
          String(renderColumnsQuantity(selectedTime)),
        )
        .replaceAll('{{{deviceId}}}', String(deviceId))
        // use separate dates for prev daily calculation because
        .replaceAll('{{{fromNextDay}}}', moment(from).add(1, 'day').toISOString())
        .replaceAll('{{{toNextDay}}}', moment(to).add(2, 'hour').toISOString())

      if (usePrevDaily) {
        preparedQueryString = preparedQueryString
          .replaceAll('{{{from}}}', moment(from).add(1, 'day').toISOString())
          .replaceAll('{{{to}}}', moment(to).add(2, 'hour').toISOString())
      } else {
        preparedQueryString = preparedQueryString
          .replaceAll('{{{from}}}', moment(from).toISOString())
          .replaceAll('{{{to}}}', moment(to).toISOString())
      }
      return gql`
        ${preparedQueryString}
      `
    }, [deviceId, from, queryString, to])

  const enabledDashboardDryings = getConfigItemValue(
    'dashboard.quantityIndications.dryingElements',
  ).filter((oneDrying: any) => oneDrying.enabled)

  const { data: dryingData, loading: loadingDrying } = useQuery(
    prepareQuery(QUERY_FOR_DRYING(enabledDashboardDryings.length)),
    getQueryRefetchParams(selectedTime, pollInterval),
  )

  const enabledDashboardInputs = getConfigItemValue(
    'dashboard.quantityIndications.inputElements',
  ).filter((oneInput: any) => oneInput.enabled)

  const { data: inputData, loading: loadingInput } = useQuery(
    prepareQuery(QUERY_FOR_INPUT(enabledDashboardInputs.length)),
    getQueryRefetchParams(selectedTime, pollInterval),
  )

  const enabledDashboardOutputs = getConfigItemValue(
    'dashboard.quantityIndications.outputElements',
  ).filter((oneOutput: any) => oneOutput.enabled)

  const { data: outputData, loading: loadingOutput } = useQuery(
    prepareQuery(QUERY_FOR_OUTPUT(enabledDashboardOutputs.length)),
    getQueryRefetchParams(selectedTime, pollInterval),
  )

  const prepareData = (queryData: { timestamp: string; value: number }[]) => {
    const arrayWithData = queryData || []
    const preparedData = arrayWithData.map((details) => {
      return {
        value: Number.isNaN(Number(details.value))
          ? 0
          : Math.round(details.value * 100) / 100,
        xAxisData: formatXAxis({ selectedTime, timestamp: details.timestamp }),
      }
    })
    return fillChartFutureValuesWithZeros({ preparedData, selectedTime })
  }

  const { data: inputDataDaily, loading: inputDataDailyLoading } = useQuery(
    prepareQuery(
      isToday(selectedTime)
        ? QUERY_FOR_INPUT_CURRENT_DAILY
        : QUERY_FOR_INPUT_PREV_DAILY,
      !isToday(selectedTime)
    ),
    getQueryRefetchParams(selectedTime, pollInterval),
  )

  const { data: outputDataDaily, loading: outputDataDailyLoading } = useQuery(
    prepareQuery(
      isToday(selectedTime)
        ? QUERY_FOR_OUTPUT_CURRENT_DAILY(numberOfOutputs)
        : QUERY_FOR_OUTPUT_PREV_DAILY(numberOfOutputs),
      !isToday(selectedTime)
    ),
    getQueryRefetchParams(selectedTime, pollInterval),
  )

  const { data: dryingDataDaily, loading: dryingDataDailyLoading } = useQuery(
    prepareQuery(
      isToday(selectedTime)
        ? QUERY_FOR_DRYING_CURRENT_DAILY
        : QUERY_FOR_DRYING_PREV_DAILY,
      !isToday(selectedTime)
    ),
    getQueryRefetchParams(selectedTime, pollInterval),
  )

  const roundChartValue = (value: number) => Math.round(value * 100) / 100

  const renderQuantityIndicationChartBoxProps = () => {
    const inputs = getConfigItemValue(
      'productionQuantities.quantityIndications.inputElements',
    )
      .filter((oneInput: any) => oneInput.enabled)
      .map((oneInput: any, index: number) => {
        return {
          color: oneInput.color,
          data: prepareData(inputData?.[`input_${index + 1}`]?.[0]?.values),
          name: translate(oneInput.translation),
          type: CHART_TYPES.LINE,
        }
      })
    const dryings = getConfigItemValue(
      'productionQuantities.quantityIndications.dryingElements',
    )
      .filter((oneDrying: any) => oneDrying.enabled)
      .map((oneDrying: any, index: number) => {
        return {
          color: oneDrying.color,
          data: prepareData(dryingData?.[`drying_${index + 1}`]?.[0]?.values),
          name: translate(oneDrying.translation),
          type: CHART_TYPES.LINE,
        }
      })
    const outputs = getConfigItemValue(
      'productionQuantities.quantityIndications.outputElements',
    )
      .filter((oneOutput: any) => oneOutput.enabled)
      .map((oneOutput: any, index: number) => {
        return {
          color: oneOutput.color,
          data: prepareData(outputData?.[`output_${index + 1}`]?.[0]?.values),
          name: translate(oneOutput.translation),
          type: CHART_TYPES.LINE,
        }
      })
    const unitSummary = getConfigItemValue(
      'productionQuantities.quantityIndications.unitSummary',
    )

    const inputSum = inputDataDaily?.input?.[0]?.value
    const outputSums = outputElements.map(
      (_: any, index: number) =>
        outputDataDaily && outputDataDaily[`output_${index + 1}`]?.[0]?.value,
    )

    const dryingSum = dryingDataDaily?.drying?.[0]?.value

    const statisticsData: any[] = []
    if (enabledDashboardInputs.length) {
      statisticsData.push({
        name: translate(
          getConfigItemValue(
            'productionQuantities.quantityIndications.inputElements.0.translation',
          ),
        ),
        value: `${formatNumber(
          roundChartValue(
            enabledDashboardInputs
              .map((oneInput: any) => {
                return {
                  ...oneInput,
                  sum: inputSum,
                }
              })
              .map((a: any) => a.sum)
              .reduce((a: any, b: any) => +a + +b, 0),
          ),
        )} ${unitSummary}`,
      })
    }
    if (enabledDashboardDryings.length) {
      statisticsData.push({
        name: translate(
          getConfigItemValue(
            'productionQuantities.quantityIndications.dryingElements.0.translation',
          ),
        ),
        value: `${formatNumber(
          roundChartValue(
            enabledDashboardDryings
              .map((oneDrying: any) => {
                return {
                  ...oneDrying,
                  sum: dryingSum,
                }
              })
              .map((a: any) => a.sum)
              .reduce((a: any, b: any) => +a + +b, 0) || 0,
          ),
        )} ${unitSummary}`,
      })
    }
    if (enabledDashboardOutputs.length) {
      outputElements.forEach((output: any, index: number) => {
        statisticsData.push({
          name: translate(output.translation),
          value: `${formatNumber(
            roundChartValue(outputSums[index]),
          )} ${unitSummary}`,
        })
      })
    }

    return {
      series: {
        data: [...inputs, ...dryings, ...outputs],
        loading: loadingInput || loadingDrying || loadingOutput,
      },
      statistics: {
        data: statisticsData,
        loading:
          inputDataDailyLoading ||
          outputDataDailyLoading ||
          dryingDataDailyLoading,
      },
      title: translate(
        getConfigItemValue(
          'productionQuantities.quantityIndications.translation',
        ),
      ),
      titleDetails: (
        <ButtonComponent
          onClick={() => history(MENU.PRODUCTION_QUANTITIES.HREF)}
          text={translate('details')}
        />
      ),
    }
  }

  const renderQuantityIndicationDashboardChartBoxProps = () => {
    const inputSum = inputDataDaily?.input?.[0]?.value
    const outputSums = outputElements.map(
      (_: any, index: number) =>
        outputDataDaily && outputDataDaily[`output_${index + 1}`]?.[0]?.value,
    )
    const dryingSum = dryingDataDaily?.drying?.[0]?.value

    const inputs = enabledDashboardInputs.map(
      (oneInput: any, index: number) => {
        return {
          color: oneInput.color,
          data: prepareData(inputData?.[`input_${index + 1}`]?.[0]?.values),
          name: translate(oneInput.translation),
          type: CHART_TYPES.LINE,
        }
      },
    )
    const dryings = enabledDashboardDryings.map(
      (oneDrying: any, index: number) => {
        return {
          color: oneDrying.color,
          data: prepareData(dryingData?.[`drying_${index + 1}`]?.[0]?.values),
          name: translate(oneDrying.translation),
          type: CHART_TYPES.LINE,
        }
      },
    )

    const outputs = enabledDashboardOutputs.map(
      (oneOutput: any, index: number) => {
        return {
          color: oneOutput.color,
          data: prepareData(outputData?.[`output_${index + 1}`]?.[0]?.values),
          name: translate(oneOutput.translation),
          type: CHART_TYPES.LINE,
        }
      },
    )

    const unitSummary = getConfigItemValue(
      'dashboard.quantityIndications.unitSummary',
    )
    const statisticsData: any[] = []
    if (enabledDashboardInputs.length) {
      statisticsData.push({
        name: translate(
          getConfigItemValue(
            'dashboard.quantityIndications.inputSummaryTranslation',
          ),
        ),
        value: `${formatNumber(
          roundChartValue(
            enabledDashboardInputs
              .map((oneInput: any) => {
                return {
                  ...oneInput,
                  sum: inputSum,
                }
              })
              .map((a: any) => a.sum)
              .reduce((a: any, b: any) => +a + +b, 0),
          ),
        )} ${unitSummary}`,
      })
    }
    if (enabledDashboardDryings.length) {
      statisticsData.push({
        name: translate(
          getConfigItemValue(
            'dashboard.quantityIndications.dryingSummaryTranslation',
          ),
        ),
        value: `${formatNumber(
          roundChartValue(
            enabledDashboardDryings
              .map((oneDrying: any) => {
                return {
                  ...oneDrying,
                  sum: dryingSum,
                }
              })
              .map((a: any) => a.sum)
              .reduce((a: any, b: any) => +a + +b, 0) || '-',
          ),
        )} ${unitSummary}`,
      })
    }
    if (enabledDashboardOutputs.length) {
      outputElements.forEach((output: any, index: number) => {
        statisticsData.push({
          name: translate(output.translation),
          value: `${formatNumber(
            roundChartValue(outputSums[index]),
          )} ${unitSummary}`,
        })
      })
    }
    return {
      series: {
        data: [...inputs, ...dryings, ...outputs],
        loading:
          (!inputData && loadingInput) ||
          (!dryingData && loadingDrying) ||
          (!outputData && loadingOutput),
      },
      statistics: {
        data: statisticsData,
        loading:
          (!inputDataDaily && inputDataDailyLoading) ||
          (!dryingDataDaily && dryingDataDailyLoading) ||
          (!outputDataDaily && outputDataDailyLoading),
      },
      title: translate(
        getConfigItemValue('dashboard.quantityIndications.translation'),
      ),
      titleDetails: (
        <ButtonComponent
          onClick={() => history(MENU.PRODUCTION_QUANTITIES.HREF)}
          text={translate('details')}
        />
      ),
    }
  }

  const renderProcessingRateChartBoxPropsDaily = ({
    unit,
  }: {
    unit: string
  }) => {
    const calculateProcessingRateValues = () => {
      const result: { timestamp: string; value: number }[] = []
      if (!inputData?.input_1?.[0] || !outputData?.output_1?.[0]) return result

      const cumulatedOutput = outputData?.output_1?.[0]?.values.map(
        (item: { timestamp: string; value: number }, valueIndex: number) => {
          let sum = 0
          outputElements.forEach((element: any, elementIndex: number) => {
            sum += outputData[`output_${elementIndex + 1}`][0]?.[valueIndex]?.value
          })
          return {
            timestamp: item.timestamp,
            value: sum,
          }
        },
      )

      // use input_1 as enumeration reference -> there should always be the same amount of data
      inputData.input_1[0].values.forEach(
        (item: { timestamp: string; value: number }, index: number) => {
          const dividend = roundChartValue(cumulatedOutput[index]?.value || 0)
          const divisor = roundChartValue(inputData?.input_1?.[0].values[index]?.value || 1)
          const quotient = dividend / divisor
          result.push({
            ...item,
            value: Math.round(quotient * 10000) / 100,
          })
        },
      )
      return result
    }
    const processingRateData = calculateProcessingRateValues()
    const preparedData = prepareData(processingRateData)

    const inputSum = inputDataDaily?.input?.[0]?.value
    const outputSum = outputElements
      .map(
        (_: any, index: number) =>
          outputDataDaily && outputDataDaily[`output_${index + 1}`]?.[0]?.value,
      )
      .reduce((a: number, b: number) => +a + +b, 0)

    const avg = roundChartValue(
      (roundChartValue(outputSum) / roundChartValue(inputSum)) * 100,
    )
    return {
      series: {
        data: [
          {
            color: CHART_COLORS.COLOR_4,
            data: preparedData,
            name: translate('chart.processingRate'),
            type: CHART_TYPES.LINE,
          },
        ],
        loading:
          inputDataDailyLoading ||
          outputDataDailyLoading ||
          dryingDataDailyLoading ||
          loadingInput ||
          loadingDrying ||
          loadingOutput,
      },
      title: translate(
        getConfigItemValue('productionQuantities.processingRate.translation'),
      ),
      titleDetails: (
        <TitleDetailsComponent
          iconName="average"
          value={`${formatNumber(avg)} ${unit}`}
        />
      ),
    }
  }

  return {
    renderQuantityIndicationChartBoxProps,
    renderQuantityIndicationDashboardChartBoxProps,
    renderProcessingRateChartBoxPropsDaily,
  }
}
