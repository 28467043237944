import LoaderComponent from 'components/Loader/Loader'
import Message from 'components/Message/Message'
import useMessageList from 'hooks/useMessageList'
import usePlsTranslation from 'hooks/usePlsTranslation'
import React, { useContext } from 'react'
import DeviceContext from 'contexts/device'
import styles from './index.module.scss'

const MessageListPage = () => {
  const { translate } = usePlsTranslation()
  const { deviceId } = useContext(DeviceContext)
  const { loading, messageList } = useMessageList({ deviceId })

  return (
    <div className={styles.messageListPage__container}>
      {loading && (
        <LoaderComponent style={{ height: '113px', width: '100%' }} />
      )}
      {!loading && messageList.length === 0 && (
        <Message
          content={translate('noMessages.description')}
          id={0}
          title={translate('noMessages.title')}
        />
      )}
      {!loading
        && messageList.length > 0
        && messageList.map((message) => (
          <Message
            content={message.content}
            date={message.date}
            id={message.id}
            key={message.id}
            status={message.status}
            time={message.time}
            title={message.title}
          />
        ))}
    </div>
  )
}

export default MessageListPage
