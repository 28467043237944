import ButtonComponent from 'components/Button/Button'
import usePlsTranslation from 'hooks/usePlsTranslation'
import { gql, useQuery } from '@apollo/client'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import MENU from 'constants/menu'
import { QUERY_FOR_DASHBOARD_DRYING } from 'graphql/queries'
import { getConfigItemValue } from 'utils/config'
import moment from 'moment'

interface ISimpleDryingChartsConfig {
  warningUpperActive: boolean
  warningLowerActive: boolean
  alarmUpperActive: boolean
  alarmLowerActive: boolean
  alarm: {
    max: number
    min: number
  }
  maxValue: number
  name: string
  onClick: () => void
  translation?: string
  unit: string
  value: number
  warning: {
    max: number
    min: number
  }
}
interface IUseChartBoxProps {
  deviceID: number
}

export default ({ deviceID }: IUseChartBoxProps) => {
  const { translate } = usePlsTranslation()
  const temperaturesCount = getConfigItemValue(
    'dashboard.drying.elements',
  ).filter((element: any) => element.type === 'temperature').length
  const history = useNavigate()
  const toDate = moment().add('1', 'day').format() // do this to be able to do polling with same variables
  const pollInterval = getConfigItemValue('dashboard.pollInterval')
  const prepareQueryVariables = () =>
    useMemo(() => {
      return {
        deviceId: String(deviceID),
        to: toDate,
      }
    }, [deviceID])

  const { data, loading } = useQuery(
    gql`
      ${QUERY_FOR_DASHBOARD_DRYING(temperaturesCount)}
    `,
    {
      variables: prepareQueryVariables(),
      ...(pollInterval && { pollInterval }),
      notifyOnNetworkStatusChange: true,
    },
  )

  const renderDryingChartBoxProps = () => {
    return {
      series: {
        data: [],
        loading,
        name: '',
        type: '',
      },
      title: translate(getConfigItemValue('dashboard.drying.translation')),
      titleDetails: (
        <ButtonComponent
          text={translate('details')}
          onClick={() => history(MENU.DRYING.HREF)}
        />
      ),
    }
  }

  const simpleDryingChartsConfig: ISimpleDryingChartsConfig[] = []
  const dryingElements = getConfigItemValue('dashboard.drying.elements')
  const onClick = () => history(MENU.DRYING.HREF)
  const unit = getConfigItemValue('dashboard.drying.unit')
  dryingElements
    .filter((element: any) => element.type === 'temperature')
    .forEach((item: ISimpleDryingChartsConfig, index: number) => {
      // Calculate the starting index in the bulkData values array for this item
      // Assuming each item has 9 data points (current, alarm upper/lower, warning upper/lower, each with active states)
      const baseIndex = index * 9

      const values = data?.bulkData || []
      const temperatureData = values[baseIndex]?.values[0] // Current temperature data
      const alarmUpperActive = values[baseIndex + 2]?.values[0]
      const alarmLowerActive = values[baseIndex + 4]?.values[0]
      const warningUpperActive = values[baseIndex + 6]?.values[0]
      const warningLowerActive = values[baseIndex + 8]?.values[0]
      const alarmUpper = values[baseIndex + 1]?.values[0]
      const alarmLower = values[baseIndex + 3]?.values[0]
      const warningUpper = values[baseIndex + 5]?.values[0]
      const warningLower = values[baseIndex + 7]?.values[0]

      simpleDryingChartsConfig.push({
        warningUpperActive: warningUpperActive?.value,
        warningLowerActive: warningLowerActive?.value,
        alarmUpperActive: alarmUpperActive?.value,
        alarmLowerActive: alarmLowerActive?.value,
        maxValue: alarmUpper?.value ?? item.maxValue,
        name: translate(item.translation || ''),
        unit,
        onClick,
        value: temperatureData?.value ?? 0,
        alarm: {
          min: alarmLower?.value ?? 0,
          max: alarmUpper?.value ?? item.maxValue,
        },
        warning: {
          min: warningLower?.value ?? 0,
          max: warningUpper?.value ?? item.maxValue,
        },
      })
    })

  const burner = dryingElements.find(
    (element: any) => element.type === 'burner',
  )
  if (burner) {
    // Assuming `temperaturesCount` temperature sensors, each with 9 data points,
    // the burner data starts immediately after
    const baseIndexForBurner = temperaturesCount * 9 // Adjust based on actual structure

    const values = data?.bulkData || []

    // Accessing the burner performance data and its alarms/warnings from the bulkData values array
    const burnerPerformanceValue = values[baseIndexForBurner]?.values[0] // Current value
    const burnerAlarmUpper = values[baseIndexForBurner + 1]?.values[0]
    const burnerAlarmUpperActive = values[baseIndexForBurner + 2]?.values[0]
    const burnerAlarmLower = values[baseIndexForBurner + 3]?.values[0]
    const burnerAlarmLowerActive = values[baseIndexForBurner + 4]?.values[0]
    const burnerWarningUpper = values[baseIndexForBurner + 5]?.values[0]
    const burnerWarningUpperActive =
      values[baseIndexForBurner + 6]?.values[0]
    const burnerWarningLower = values[baseIndexForBurner + 7]?.values[0]
    const burnerWarningLowerActive =
      values[baseIndexForBurner + 8]?.values[0]

    simpleDryingChartsConfig.push({
      warningUpperActive: burnerWarningUpperActive?.value,
      warningLowerActive: burnerWarningLowerActive?.value,
      alarmUpperActive: burnerAlarmUpperActive?.value,
      alarmLowerActive: burnerAlarmLowerActive?.value,
      maxValue: burnerAlarmUpper?.value ?? burner.maxValue,
      name: translate(burner.translation),
      unit,
      onClick,
      value: burnerPerformanceValue?.value ?? 0,
      alarm: {
        min: burnerAlarmLower?.value ?? 0,
        max: burnerAlarmUpper?.value ?? burner.maxValue,
      },
      warning: {
        min: burnerWarningLower?.value ?? 0,
        max: burnerWarningUpper?.value ?? burner.maxValue,
      },
    })
  }

  return {
    renderDryingChartBoxProps,
    simpleDryingChartsConfig,
    loading: loading && !data,
  }
}
