import { gql, useQuery } from '@apollo/client'
import CHART_TYPES from 'constants/chartTypes'
import { QUERY_FOR_TEMPERATURES } from 'graphql/queries'
import usePlsTranslation from 'hooks/usePlsTranslation'
import { useMemo } from 'react'
import moment from 'moment'
import {
  fillChartFutureValuesWithZeros,
  formatXAxis,
  renderColumnsQuantity,
  renderTimeRange,
  getQueryRefetchParams,
} from 'utils/charts'
import { getConfigItemValue } from 'utils/config'

interface IUseChartBoxProps {
  deviceId: number
  selectedTime: string
}

export default ({ deviceId, selectedTime }: IUseChartBoxProps) => {
  const { translate } = usePlsTranslation()
  const { from, to } = renderTimeRange(selectedTime)
  const prepareQuery = (queryString: string) =>
    useMemo(() => {
      const segments = selectedTime.split('-')
      const aggregation = segments.length === 3 ? 'TimeWeightedAverage' : 'Max'
      const preparedQueryString = queryString
        .replaceAll('{{{aggregation}}}', aggregation)
        .replaceAll(
          '{{{calculationTimeRange}}}',
          String(renderColumnsQuantity(selectedTime)),
        )
        .replaceAll('{{{deviceId}}}', String(deviceId))
        .replaceAll('{{{from}}}', moment(from).toISOString())
        .replaceAll('{{{to}}}', moment(to).toISOString())
      return gql`
        ${preparedQueryString}
      `
    }, [deviceId, from, queryString, to])
  const prepareData = (
    arrayWithData: { timestamp: string; value: number }[],
  ) => {
    const preparedData = arrayWithData.map((details) => {
      return {
        value: Math.round(details.value * 100) / 100,
        xAxisData: formatXAxis({ selectedTime, timestamp: details.timestamp }),
      }
    })
    return fillChartFutureValuesWithZeros({ preparedData, selectedTime })
  }
  const renderTemperaturesChartBoxProps = () => {
    const temperatures = getConfigItemValue(
      'drying.temperatures.elements',
    ).filter((element: any) => element.type === 'temperature')
    const { data: temperaturesData, loading: temperaturesLoading } = useQuery(
      prepareQuery(QUERY_FOR_TEMPERATURES(temperatures.length)),
      getQueryRefetchParams(selectedTime, 0)
    )

    return {
      series: {
        data: temperatures.map((temp: any, index: number) => {
          return {
            color: temp.color,
            data: prepareData(
              temperaturesData?.[`temperature_${index + 1}`]?.[0]?.values || [],
            ),
            name: translate(temp.translation),
            type: CHART_TYPES.LINE,
          }
        }),
        loading: temperaturesLoading,
      },
      title: translate(getConfigItemValue('drying.temperatures.translation')),
    }
  }

  return {
    renderTemperaturesChartBoxProps,
  }
}
