import React from 'react'
import Card from 'components/Card/Card'
import SimpleChartComponent from 'components/SimpleChart/SimpleChart'
import useDryingChartBox from 'hooks/useDryingChartBox'
import DryingInterface from './Drying.interface'
import styles from './Drying.module.scss'

const Drying = (props: DryingInterface) => {
  const { deviceID } = props
  const { renderDryingChartBoxProps, simpleDryingChartsConfig, loading } =
    useDryingChartBox({
      deviceID,
    })
  const { title, titleDetails } = renderDryingChartBoxProps()
  return (
    <Card title={title} titleDetails={titleDetails}>
      <div className={styles.useBoxOverview__simpleChartsContainer}>
        {simpleDryingChartsConfig.map((simpleDryingChartConfig) => (
          <SimpleChartComponent
            errorUpperSet={simpleDryingChartConfig.alarmUpperActive}
            errorLowerSet={simpleDryingChartConfig.alarmLowerActive}            
            warningUpperSet={simpleDryingChartConfig.warningUpperActive}
            warningLowerSet={simpleDryingChartConfig.warningLowerActive}
            key={simpleDryingChartConfig.name}
            maxValue={simpleDryingChartConfig.maxValue}
            name={simpleDryingChartConfig.name}
            unit={simpleDryingChartConfig.unit}
            value={simpleDryingChartConfig.value}
            loading={loading}
            warning={simpleDryingChartConfig.warning}
            alarm={simpleDryingChartConfig.alarm}
            onClick={simpleDryingChartConfig.onClick}
          />
        ))}
      </div>
    </Card>
  )
}

export default Drying
