import {
  faAngleLeft,
  faAngleRight,
  faArrowRightFromBracket,
  faBars,
  faBell,
  faBolt,
  faCalendarDay,
  faChartLine,
  faChevronRight,
  faCircleCheck,
  faCircleExclamation,
  faCircle,
  faClock,
  faGauge,
  faHardDrive,
  faListCheck,
  faWallet,
  faWind,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import IIconProps from './Icon.interface'
import styles from './Icon.module.scss'

const IconComponent = ({
  className = '',
  iconName,
  onClick = () => null,
}: IIconProps) => {
  switch (iconName) {
    case 'angleLeft': {
      return (
        <FontAwesomeIcon
          className={className}
          icon={faAngleLeft}
          onClick={onClick}
        />
      )
    }
    case 'angleRight': {
      return (
        <FontAwesomeIcon
          className={className}
          icon={faAngleRight}
          onClick={onClick}
        />
      )
    }
    case 'arrowRightFromBracket': {
      return (
        <FontAwesomeIcon className={className} icon={faArrowRightFromBracket} />
      )
    }
    case 'average': {
      return <span className={`${styles.icon} ${styles['icon--fi']}`} />
    }
    case 'bars': {
      return (
        <FontAwesomeIcon
          className={className}
          icon={faBars}
          onClick={onClick}
        />
      )
    }
    case 'bell': {
      return <FontAwesomeIcon className={className} icon={faBell} />
    }
    case 'bolt': {
      return <FontAwesomeIcon className={className} icon={faBolt} />
    }
    case 'calendar': {
      return <FontAwesomeIcon className={className} icon={faCalendarDay} />
    }
    case 'chart': {
      return <FontAwesomeIcon className={className} icon={faChartLine} />
    }
    case 'checklist': {
      return <FontAwesomeIcon className={className} icon={faListCheck} />
    }
    case 'checkmarkInCircle': {
      return <FontAwesomeIcon className={className} icon={faCircleCheck} />
    }
    case 'circle': {
      return <FontAwesomeIcon className={className} icon={faCircle} />
    }
    case 'chevronRight': {
      return (
        <FontAwesomeIcon
          className={className}
          icon={faChevronRight}
          onClick={onClick}
        />
      )
    }
    case 'clock': {
      return <FontAwesomeIcon className={className} icon={faClock} />
    }
    case 'exclamationMarkInCircle': {
      return (
        <FontAwesomeIcon className={className} icon={faCircleExclamation} />
      )
    }
    case 'gauge': {
      return <FontAwesomeIcon className={className} icon={faGauge} />
    }
    case 'hardDrive': {
      return <FontAwesomeIcon className={className} icon={faHardDrive} />
    }
    case 'sum': {
      return <span className={`${styles.icon} ${styles['icon--sigma']}`} />
    }
    case 'wallet': {
      return <FontAwesomeIcon className={className} icon={faWallet} />
    }
    case 'wind': {
      return <FontAwesomeIcon className={className} icon={faWind} />
    }
    default: {
      return null
    }
  }
}

export default IconComponent
