import { gql, useQuery } from '@apollo/client'
import ButtonComponent from 'components/Button/Button'
import MENU from 'constants/menu'
import usePlsTranslation from 'hooks/usePlsTranslation'
import React, { useMemo } from 'react'
import { LAST_VALUE_START_DATE } from 'utils/common'
import { useNavigate } from 'react-router-dom'
import { QUERY_FOR_DASHBOARD_SORTING_MACHINES } from 'graphql/queries'
import { getConfigItemValue } from 'utils/config'
import moment from 'moment'

interface IUseChartBoxProps {
  deviceID: number
}

export default ({ deviceID }: IUseChartBoxProps) => {
  const { translate } = usePlsTranslation()
  const history = useNavigate()
  const fromDate = LAST_VALUE_START_DATE
  const toDate = moment().add('1', 'day').format() // do this to be able to do polling with same variables
  const machineCount = getConfigItemValue('sortingMachines.elements').length
  const pollInterval = getConfigItemValue('dashboard.pollInterval')
  const prepareQueryVariables = () =>
    useMemo(() => {
      return {
        deviceId: String(deviceID),
        from: fromDate,
        to: toDate,
      }
    }, [deviceID])

  const { data, loading } = useQuery(
    gql`
      ${QUERY_FOR_DASHBOARD_SORTING_MACHINES(machineCount)}
    `,
    {
      variables: prepareQueryVariables(),
      ...(pollInterval && { pollInterval }),
      notifyOnNetworkStatusChange: true,
    },
  )

  let noErrorWarningMachineCount = 0
  let noErrorMachineCount = 0
  let errorMachineCount = 0

  if (data && data.bulkData && data.bulkData.values) {
    for (let i = 0; i < machineCount; i += 1) {
      // Assuming each machine has two entries: one for error and one for warning, in sequence
      const errorData = data.bulkData.values[i * 2] // Error entries are at even indices: 0, 2, 4, ...
      const warningData = data.bulkData.values[i * 2 + 1] // Warning entries are at odd indices: 1, 3, 5, ...

      // Assuming the structure where each entry in bulkData.values looks like { value, timestamp }
      const isErrorPresent = errorData && errorData.value === 1
      const isWarningPresent = warningData && warningData.value === 1

      if (!isErrorPresent && !isWarningPresent) {
        noErrorWarningMachineCount += 1
      } else if (!isErrorPresent && isWarningPresent) {
        noErrorMachineCount += 1
      } else if (isErrorPresent) {
        errorMachineCount += 1
      }
    }
  }

  const renderSortingMachinesChartBoxProps = () => {
    return {
      series: {
        data: [],
        loading,
        name: '',
        type: '',
      },
      title: translate(
        getConfigItemValue('dashboard.sortingMachines.translation'),
      ),
      titleDetails: (
        <ButtonComponent
          text={translate('NMachines', {
            machinesQuantity: machineCount,
          })}
          onClick={() => history(MENU.SORTING_MACHINES.HREF)}
        />
      ),
    }
  }
  const sortingMachines = [
    {
      iconModifier: 'green',
      text: translate('machineStatus.perfect'),
      value: noErrorWarningMachineCount,
    },
    {
      iconModifier: 'orange',
      text: translate('machineStatus.warning'),
      value: noErrorMachineCount,
    },
    {
      iconModifier: 'red',
      text: translate('machineStatus.error'),
      value: errorMachineCount,
    },
  ]

  return {
    renderSortingMachinesChartBoxProps,
    sortingMachines,
    loading: loading && !data,
  }
}
